import Vue from 'vue'

import flatpickr from "flatpickr"
import { German } from "flatpickr/dist/l10n/de.js"
flatpickr.localize(German);
flatpickr.setDefaults(
    {
        altInput: true,
        altFormat: 'd.m.Y',
        dateFormat : 'Y-m-d'
    }
);

import VueFlatPicker from 'vue-flatpickr-component'

import 'flatpickr/dist/flatpickr.css'

Vue.use(VueFlatPicker);
