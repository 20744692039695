import db from '../../services/db'

export default {
    state: {
        data: []
    },
    getters: {
        getNewsletters (state, getters) {
            return state.data;
        }
    },
    actions: {
        fetchNewsletters ({commit}, payload) {
            db.list('newsletter', JSON.stringify( payload )).then((response) => {
                let data = response.data;
                commit('setNewsletters', data.data)
            }).catch((error) => {
                console.log(error)
            });
        }
    },
    mutations: {
        setNewsletters (state, data) {
            state.data = data
        },
        deleteNewsletter (state, index) {
            state.data.splice(index, 1);
        }
    }
};
