<template>
    <div class="iq-top-navbar">
        <div class="top-menu">
            <b-container fluid>
                <b-row>
                    <b-col md="12">
                        <div class="iq-navbar-custom-menu d-flex align-items-center justify-content-between">
                            <div class="iq-sidebar-logo">
                                <div class="top-logo">
                                    <router-link :to="homeURL">
                                        <img :src="logo" class="img-fluid" alt="logo">
                                    </router-link>
                                </div>
                            </div>
                            <nav class="navbar navbar-expand-lg navbar-light p-0">
                                <!-- <b-navbar-toggle target="nav-collapse" class="text-primary">
                                    <i class="ri-menu-3-line"></i>
                                </b-navbar-toggle> -->
                                <div class="iq-menu-bt align-self-center">
                                    <div class="wrapper-menu" @click="miniSidebar">
                                        <div class="main-circle"><i class="ri-arrow-left-s-line"></i></div>
                                        <div class="hover-circle"><i class="ri-arrow-right-s-line"></i></div>
                                    </div>
                                </div>
                                <slot name="right"/>
                            </nav>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <div class="tab-menu-horizontal">
            <div class="container-fluid">
                <div class="row">
                    <div class="iq-menu-horizontal-tab">
                        <div class="iq-sidebar-menu">
                            <MainMenu :items="getAllowedItems()" id="main-menu" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SideBarItems from '../../data/SideBar'
export default {
    name: 'MenuBar',
    props: {
        user: { type: Object }
    },
    mounted () {},
    components: {},
    computed: {
        items () {
            return SideBarItems
        },
        is_admin () {
            return (this.user.role_id === 1)
        },
        permissions () {
            return this.user.role.permissions.split(",")
        }
    },
    created () {},
    data () {
        return {
            homeURL: { name: 'dashboard.home' },
            logo: require('../../../../images/logo.png')
        }
    },
    methods: {
        getAllowedItems () {
            if (this.is_admin) {
                return this.items
            } else {
                return _.map( this.items, (value, key) => {
                    let childs = [];
                    _.forEach( value.children, (v, k) => {
                        if (_.includes( this.permissions, v.key )) {
                            childs.push( v );
                        }
                    });
                    value.children = childs;
                    return value;
                });
            }
        },
        miniSidebar () {
            this.$emit('toggle')
        }
    }
}
</script>

<style scoped lang="scss">
.collapse-menu{
    @media (min-width:1300px) {
        display: none;
    }
}
.iq-sidebar-menu .iq-menu.hover-menu{
    display: flex;
    @media (max-width:1299px){
        display: none !important;
    }
}
</style>
