<template>
    <span>{{ params.value | dataJSON(params.path) }}</span>
</template>

<script>
    import Vue from 'vue'

    export default Vue.extend({
        mixins: [
            {
                data () {},
                mounted () {},
                components: {},
                methods: {}
            }
        ]
    })
</script>
