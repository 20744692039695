<template>
  <ul class="iq-menu d-flex justify-content-left" :id="id">
    <li>
      <router-link :to="{ name: 'dashboard.home' }">
        <i class="ri-home-4-line" />
        <span>Home</span>
      </router-link>
    </li>
    <li v-for="(item,index) in items" v-if="item.children.length > 0" :key="index">
      <i v-if="item.is_heading" class="ri-subtract-line" />
      <span v-if="item.is_heading">{{ $t(item.name) }}</span>
      <router-link :to="item.link" v-if="!item.is_heading" class="iq-waves-effect collapsed" :class="activeLink(item) && item.children ? 'active' : activeLink(item) ? 'active' : ''">
        <i :class="item.icon" v-if="item.is_icon_class"/>
        <template v-else v-html="item.icon"></template>
        <span>{{ $t(item.name) }}</span>
        <i v-if="item.children" class="ri-arrow-right-s-line iq-arrow-right" />
      </router-link>
      <SubMenu v-if="item.children" :items="item.children" :id="item.name" />
    </li>
  </ul>
</template>
<script>
import { vito } from '../../config/pluginInit'
export default {
  name: 'MainMenu',
  props: {
    items: Array,
    id: String
  },
  data () {
    return {}
  },
  created () {},
  components: {},
  computed: {},
  mounted () {},
  methods: {
    activeLink (item) {
      return vito.getActiveLink(item, this.$route.name)
    }
  }
}
</script>
