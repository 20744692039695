<template>
    <div>

        <ag-grid-vue id="ag-grid" class="ag-theme-balham height-400"
                     :gridOptions="gridOptions"
                     @gridReady="onGridReady"
                     :columnDefs="columnDefs"
                     :defaultColDef="defaultColDef"
                     :rowData="rowData"
                     :floatingFilter="false"
                     :modules="modules"
                     :pagination="true"
                     :cacheBlockSize="cacheBlockSize"
                     :paginationPageSize="paginationPageSize"
                     :rowHeight="50"
                     :overlayLoadingTemplate="overlayLoadingTemplate"
                     rowSelection="single" />

        <div class="text-right" v-if="attachment">
            <b-button variant="primary" class="mt-3" @click="getSelectedRow">Set Attachment</b-button>
        </div>

        <b-modal :static="true" :lazy="true" hide-footer ref="modal-edit-file" id="modal-edit-file" size="md" title="Edit File">
            <form @submit.prevent="updateFile">
                <div class="modal-body">
                    <div class="form-group">
                        <label for="name">Name:</label>
                        <input type="text" class="form-control" required id="name"  placeholder="Please enter a file name" v-model="editFile.name">
                    </div>
                    <b-form-group label="Categories:" label-for="categories">
                        <b-form-select v-if="categories" plain v-model="editFile.category"
                                       :options="categories" value-field="_id"
                                       text-field="title" />
                    </b-form-group>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary">Save</button>
                </div>
            </form>
        </b-modal>

    </div>
</template>

<script>
    import 'ag-grid-community/dist/styles/ag-grid.min.css'
    import 'ag-grid-community/dist/styles/ag-theme-balham.css'
    import { AllCommunityModules } from '@ag-grid-enterprise/all-modules';
    import { vito } from '../../../config/pluginInit'
    import { AgGridVue } from 'ag-grid-vue'
    import db from '../../../services/db'
    import Actions from './Actions'
    import Config from '../../Table/Config'
    import Link from '../../Table/Link'
    import Date from '../../Table/Date'
    import Thumb from '../../Table/Thumb'

    export default {
        name: 'LibraryList',
        props: {
            folder: {
                type: String,
                default: 'media'
            },
            attachment: {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {
                page_title: '',
                size: 500,
                lastItem: '',
                gridOptions: null,
                gridApi: null,
                columnDefs: null,
                defaultColDef: null,
                overlayLoadingTemplate: null,
                paginationPageSize: 50,
                cacheBlockSize: 50,
                rowData: [],
                modules: AllCommunityModules,
                categories: [],
                editFile: {
                    name: '',
                    category: ''
                }
            }
        },
        components: {
            AgGridVue,
            Actions,
            Config,
            Link,
            Date,
            Thumb
        },
        beforeMount () {
            this.gridOptions = {
                enableCellChangeFlash: true,
                context: {
                    componentParent: this
                }
            };
            this.columnDefs = [
                { headerName: 'Thumbnail', field: 'file', checkboxSelection: true, cellRendererFramework: Thumb, cellStyle: {'display': 'block', 'padding': '5px', 'text-align': 'center'} },
                { headerName: 'Alt Text', field: 'name', width: 400 },
                { headerName: 'Category', field: 'category', valueFormatter: function (params) {
                    return ( params.value.title ) ? params.value.title : '';
                } },
                { headerName: 'Type', field: 'type', width: 75 },
                { headerName: 'Size', field: 'size', width: 75, valueFormatter: this.calculateSize },
                { headerName: 'Created', field: 'created_at', cellRendererFramework: Date, cellRendererParams: { time: true }, sort: 'desc' },
                { headerName: 'Actions', filter: false,  field: '_id', width: 100, cellRendererFramework: Actions }
            ];
            this.defaultColDef = {
                sortable: true,
                filter: true,
                resizable: true,
                flex: 2
            };
            this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Please wait while data is loading ...</span>';
        },
        created () {
            this.page_title = this.$route.meta.name;
        },
        mounted () {
            vito.index();
            this.gridApi = this.gridOptions.api;
            this.gridApi.showLoadingOverlay();
        },
        methods: {
            onGridReady ( params ) {
                this.loadData()
            },
            loadData () {
                let query = {
                    size: this.size,
                    folder: this.folder
                };
                db.list('media', JSON.stringify( query )).then((response) => {
                    let data = response.data;
                    this.rowData = data.data;
                }).catch((error) => {
                    console.log(error)
                });
            },
            calculateSize ( params ) {
                if ( params.value ) {
                    let sizeInMB = (params.value / (1024*1024)).toFixed(2);
                    return sizeInMB + 'MB';
                }
            },
            updateFile () {
                let id = this.editFile.id,
                    data = {
                        name: this.editFile.name,
                        category: this.editFile.category
                    };
                db.update('media', data, id).then((response) => {
                    this.$bvModal.hide('modal-edit-file');
                    this.refreshData();
                }).catch((error) => {
                    console.log(error)
                });
            },
            // openEditFile ( data ) {
            //     this.fetchCategories();
            //     this.editFile.id = data._id;
            //     this.editFile.name = data.name;
            //     this.editFile.category = data.category._id;
            //     this.$bvModal.show('modal-edit-file');
            // },
            refreshData () {
                this.loadData();
                this.gridApi.refreshCells();
            },
            getSelectedRow(event) {
                let selected = this.gridOptions.api.getSelectedNodes();
                this.$emit( 'onSelect', selected );
            }
        }
    }
</script>
