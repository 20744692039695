<template>
    <vue-dropzone :ref="ref" :id="id" :options="dzOptions" v-on:vdropzone-success="fileUploadResponse" v-on:vdropzone-removed-file="fileRemoved" />
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'

    export default {
        name: 'Gallery',
        data () {
            return {
                id: 'dropzone-gallery',
                ref: 'myVueDropzoneGallery',
                dzOptions: {
                    url: BACKEND_URL + '/media',
                    paramName: 'customFile',
                    maxFiles: 20,
                    parallelUploads: 1,
                    acceptedFiles: '.jpg,.jpeg,.png',
                    thumbnailHeight: 175,
                    addRemoveLinks: true,
                    dictRemoveFile: '<i class="ri-close-circle-fill ri-2x text-dark"></i>',
                    dictDefaultMessage: '<i class="ri-upload-cloud-fill ri-2x text-primary"></i><br> Browse or Drag & Drop'
                }
            }
        },
        components: {
            vueDropzone: vue2Dropzone,
        },
        props: {
            data: {}
        },
        mounted () {
            if ( !_.isEmpty(this.data) ) {
                let gallery = this.$refs.myVueDropzonegallery;
                this.data.forEach(function (item, index) {
                    let mockFile = { name: item.name, size: item.size, id: item._id };
                    gallery.manuallyAddFile(mockFile, item.file);
                });
            }
        },
        computed: {},
        methods: {
            fileUploadResponse: function (file, response) {
                this.$emit('file', response._id);
            },
            fileRemoved: function (file, error, xhr) {
                this.$emit('remove', file);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .vue-dropzone {
        border-width: 1px;
        margin: 0 auto;
        position: relative;
        padding: 0;
        .dz-preview {
            margin: 0 auto;
            display: block;
            .dz-image {
                text-align: center;
                img {
                    display: inline-block;
                }
            }
            .dz-details {
                .dz-size {
                    margin: 0;
                    font-size: 12px;
                }
            }
            .dz-progress {
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 20;
                width: calc(100% - 20px);
                height: 5px;
                margin: 10px;
                top: auto;

                .dz-upload {
                    height: 5px;
                    background: green;
                    display: inline-block;
                    vertical-align: top;
                }
            }
            .dz-error-message {
                opacity: 1;
                top: auto;
                bottom: -45px;
                display: none !important;
            }
            .dz-error-mark {
                path {
                    fill: red;
                }
            }
            .dz-success-mark {
                path {
                    fill: green;
                }
            }
            .dz-remove {
                position: absolute;
                top: 10px;
                right: 10px;
                color: white;
                width: 26px;
                height: 26px;
                line-height: normal;
                opacity: 1;
                border: 0;
                margin: 0;
                padding: 0;
                bottom: auto;
            }
            &.dz-success {
                .dz-success-mark {
                    opacity: 1;
                }
            }
        }
        &.multiple-allowed {
            .dz-preview {
                display: inline-block;
                margin: .2rem;
            }
        }
    }
</style>
