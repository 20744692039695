<template>
    <div>
        <b-container v-if="is_asw" fluid>
            <b-row>
                <b-col md="12">
                    <h3 class="text-center">Herzlich willkommen, <span>{{ name }}</span></h3>
                </b-col>
            </b-row>
        </b-container>
        <b-container v-else fluid>
            <b-row>
                <b-col md="12"><p class="text-right update-status">Last updated: {{ analytics.updated_at }} - <a href="javascript:void(0);" v-on:click="refreshData"><b-spinner small v-if="loading"/>
                    <span v-else>Refresh</span></a></p></b-col>
            </b-row>
            <b-row>
                <b-col md="2">
                    <div v-if="is_admin" class="iq-card iq-card-block iq-card-stretch iq-card-height">
                        <div class="iq-card-body">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <div class="value-box">
                                    <h2 class="mb-0"><span class="counter text-danger">0.73%</span></h2>
                                    <p class="mb-0 text-secondary line-height">Change</p>
                                </div>
                                <div class="iq-iconbox iq-bg-danger">
                                    <i class="ri-arrow-down-line"></i>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="value-box">
                                    <h2 class="mb-0"><span class="counter">{{ analytics.blocked }}</span></h2>
                                    <p class="mb-0 text-secondary line-height">Locked</p>
                                </div>
                                <router-link :to="{ name: 'customer.list-bc' }">
                                    <div class="iq-iconbox iq-bg-info">
                                        <i class="ri-lock-line"></i>
                                    </div>
                                 </router-link>
                            </div>
                        </div>
                    </div>
                    <iq-card v-if="is_admin">
                        <template v-slot:headerTitle>
                            <h3 class="card-title">Kündigunsrate</h3>
                        </template>
                        <template v-slot:headerAction>
                            <i class="icon icon-cancellation"></i>
                        </template>
                        <template v-slot:body>
                            <table class="table mb-0 table-borderless">
                                <thead>
                                    <tr>
                                        <th>Mitgliedschaft</th>
                                        <th>Manual</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr v-for="i in analytics.cancellation.items" :key="i.l">
                                    <td>{{ i.l }}</td>
                                    <td>{{ i.v }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </template>
                    </iq-card>
                    <iq-card>
                        <template v-slot:headerTitle>
                            <h3 class="card-title">Status</h3>
                        </template>
                        <template v-slot:headerAction>
                            <i class="icon icon-status"></i>
                        </template>
                        <template v-slot:body>
                            <table class="table mb-0 table-borderless">
                                <thead>
                                    <tr>
                                        <th>Typ</th>
                                        <th>MTD</th>
                                        <th>YTD</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr v-for="i in analytics.status.items" :key="i.l">
                                    <td>{{ i.l }}</td>
                                    <td>{{ i.a }}</td>
                                    <td>{{ i.b }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </template>
                    </iq-card>
                </b-col>
                <b-col md="6">
                    <iq-card v-if="is_admin">
                        <template v-slot:headerTitle>
                            <h3 class="card-title">Mitglieder</h3>
                        </template>
                        <template v-slot:headerAction>
                            <i class="icon icon-customers"></i>
                        </template>
                        <template v-slot:body>
                            <table class="table mb-0 table-borderless">
                                <thead>
                                <tr>
                                    <th></th>
                                    <th>MTD</th>
                                    <th>Gesamt</th>
                                    <th>Anteil</th>
                                    <th>1 J</th>
                                    <th>2 J</th>
                                    <th>3 J+</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="i in analytics.customer.items" :key="i.l">
                                    <td>{{ i.l }}</td>
                                    <td>{{ i.h }}</td>
                                    <td>{{ i.t }}</td>
                                    <td>{{ i.s }}</td>
                                    <td>{{ i.a }}</td>
                                    <td>{{ i.b }}</td>
                                    <td>{{ i.c }}</td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td>Gesamt</td>
                                    <td>{{ analytics.customer.total_h }}</td>
                                    <td>{{ analytics.customer.total_t }}</td>
                                    <td>100%</td>
                                    <td>{{ analytics.customer.total_a }}</td>
                                    <td>{{ analytics.customer.total_b }}</td>
                                    <td>{{ analytics.customer.total_c }}</td>
                                </tr>
                                </tfoot>
                            </table>
                        </template>
                    </iq-card>
                    <b-row>
                        <b-col md="6">
                            <iq-card>
                                <template v-slot:headerTitle>
                                    <h3 class="card-title">Anfragen</h3>
                                </template>
                                <template v-slot:headerAction>
                                    <i class="icon icon-forms"></i>
                                </template>
                                <template v-slot:body>
                                    <table class="table mb-0 table-borderless">
                                        <thead>
                                            <tr>
                                                <th>Kategorie</th>
                                                <th>Offen</th>
                                                <th>Antwortzeit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="i in analytics.anfragen.items" :key="i.l">
                                            <td>{{ i.l }}</td>
                                            <td>{{ i.v }}</td>
                                            <td>{{ i.t }}h <i class="ri-time-line text-primary pl-2"></i></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </template>
                            </iq-card>
                            <iq-card v-if="is_group2 || is_admin">
                                <template v-slot:headerTitle>
                                    <h3 class="card-title">Mails</h3>
                                </template>
                                <template v-slot:headerAction>
                                    <i class="icon icon-mails"></i>
                                </template>
                                <template v-slot:body>
                                    <table class="table mb-0 table-borderless">
                                        <thead>
                                        <tr>
                                            <th>Kategorie</th>
                                            <th>Letzte</th>
                                            <th>Durchschnitt</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="i in analytics.mails.items" :key="i.l">
                                            <td>{{ i.l }}</td>
                                            <td>{{ i.a }}%</td>
                                            <td>{{ i.b }}%</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </template>
                            </iq-card>
                        </b-col>
                        <b-col md="6">
                            <iq-card>
                                <template v-slot:headerTitle>
                                    <h3 class="card-title">Abonnements</h3>
                                </template>
                                <template v-slot:headerAction>
                                    <i class="icon icon-mails"></i>
                                </template>
                                <template v-slot:body>
                                    <table class="table mb-0 table-borderless">
                                        <thead>
                                        <tr>
                                            <th>Typ</th>
                                            <th>Offen</th>
                                            <th>YTD</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="i in analytics.abonnements.items" :key="i.l">
                                            <td>{{ i.l }}</td>
                                            <td>{{ i.a }}</td>
                                            <td>{{ i.b }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </template>
                            </iq-card>
                            <iq-card v-if="is_group2 || is_admin">
                                <template v-slot:headerTitle>
                                    <h3 class="card-title">Newsletter</h3>
                                </template>
                                <template v-slot:headerAction>
                                    <i class="icon icon-newsletter"></i>
                                </template>
                                <template v-slot:body>
                                    <table class="table mb-0 table-borderless">
                                        <thead>
                                        <tr>
                                            <th>Sign-ups</th>
                                            <th>Heute</th>
                                            <th>MTD</th>
                                            <th>YTD</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="i in analytics.newsletter.items" :key="i.l">
                                            <td>{{ i.l }}</td>
                                            <td>{{ i.a }}</td>
                                            <td>{{ i.b }}</td>
                                            <td>{{ i.c }}</td>
                                        </tr>
                                        </tbody>
                                        <tfoot>
                                        <!-- <tr>
                                            <td>Gesamt</td>
                                            <td>{{ analytics.newsletter.total_a }}</td>
                                            <td>{{ analytics.newsletter.total_b }}</td>
                                            <td>{{ analytics.newsletter.total_c }}</td>
                                        </tr> -->
                                        </tfoot>
                                    </table>
                                </template>
                            </iq-card>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col md="4">
                    <iq-card v-if="is_group2 || is_admin">
                        <template v-slot:headerTitle>
                            <h3 class="card-title">Bestellungen Heute</h3>
                        </template>
                        <template v-slot:headerAction>
                            <i class="icon icon-orders"></i>
                        </template>
                        <template v-slot:body>
                            <table class="table mb-0 table-borderless">
                                <thead>
                                <tr>
                                    <th>Kategorie</th>
                                    <th>Gold</th>
                                    <th>Platin</th>
                                    <th>Diamant</th>
                                    <th>Offen</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="i in analytics.orders.items" :key="i.l">
                                    <td>{{ i.l }}</td>
                                    <td>{{ i.a }}</td>
                                    <td>{{ i.b }}</td>
                                    <td>{{ i.c }}</td>
                                    <td>{{ i.d }}</td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td>Gesamt</td>
                                    <td>{{ analytics.orders.total_a }}</td>
                                    <td>{{ analytics.orders.total_b }}</td>
                                    <td>{{ analytics.orders.total_c }}</td>
                                    <td>{{ analytics.orders.total_d }}</td>
                                </tr>
                                </tfoot>
                            </table>
                        </template>
                    </iq-card>
                    <iq-card v-if="is_admin">
                        <template v-slot:headerTitle>
                            <h3 class="card-title">Gewinnspiel</h3>
                        </template>
                        <template v-slot:headerAction>
                            <i class="icon icon-gewinnspiel"></i>
                        </template>
                        <template v-slot:body>
                            <table class="table mb-0 table-borderless">
                                <thead>
                                <tr>
                                    <th></th>
                                    <th>Gesamt</th>
                                    <th>Neu NL</th>
                                    <th>Umsatz</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="i in analytics.gewinnspiel.items" :key="i.l">
                                    <td>{{ i.l }}</td>
                                    <td>{{ i.a }}</td>
                                    <td>{{ i.b }}</td>
                                    <td>{{ i.c }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </template>
                    </iq-card>
                    <iq-card v-if="is_admin">
                        <template v-slot:headerTitle>
                            <h3 class="card-title">Meilen</h3>
                        </template>
                        <template v-slot:headerAction>
                            <i class="icon icon-miles"></i>
                        </template>
                        <template v-slot:body>
                            <table class="table mb-0 table-borderless">
                                <thead>
                                <tr>
                                    <th>Meilentyp</th>
                                    <th>Heute</th>
                                    <th>MTD</th>
                                    <th>YTD</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="i in analytics.miles.items" :key="i.l">
                                    <td>{{ i.l }}</td>
                                    <td>{{ i.a }}</td>
                                    <td>{{ i.b }}</td>
                                    <td>{{ i.c }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </template>
                    </iq-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import { vito } from '../../config/pluginInit'
import db from '../../services/db'
import {mapGetters} from 'vuex'

export default {
    name: 'Dashboard',
    components: {},
    mounted () {
        this.name = this.userData.name
        this.is_admin = (this.userData.role_id === 1)
        this.is_group1 = (this.userData.role_id === 2)
        this.is_group2 = (this.userData.role_id === 3)
        this.is_asw = (this.userData.role_id === 4)
        vito.index()
    },
    data () {
        return {
            name: '',
            is_asw: false,
            is_admin: false,
            is_group1: false,
            is_group2: false,
            loading: false,
            query: {
                refresh: false
            },
            analytics: {
                blocked: 0,
                customer: {
                    total_t: 0,
                    total_h: 0,
                    total_s: 100,
                    total_a: 0,
                    total_b: 0,
                    total_c: 0,
                    items: [
                        {l:'Diamant',h:0,t:0,s:0,a:0,b:0,c:0},
                        {l:'Platin',h:0,t:0,s:0,a:0,b:0,c:0},
                        {l:'Gold',h:0,t:0,s:0,a:0,b:0,c:0},
                        {l:'Free Gold',h:0,s:0,t:0,a:0,b:0,c:0}
                    ]
                },
                cancellation: {
                    items: [
                        {l:'Diamant',v:0},
                        {l:'Platin',v:0},
                        {l:'Gold',v:0}
                    ]
                },
                anfragen: {
                    items: [
                        {l:'Allgemein',v:0,t:13.0},
                        {l:'Diamant',v:0,t:32.0},
                        {l:'Persönliche',v:0,t:26.0},
                        {l:'Wissen',v:0,t:26.0}
                    ]
                },
                gewinnspiel: {
                    items: [
                        {l:'Founders Club Event 2023',a:0,b:0,c:0},
                        {l:'Turkish Airlines Business',a:0,b:0,c:0},
                        {l:'Baumhotel My Arbor',a:0,b:0,c:0},
                        {l:'Founders Club Event 2022',a:0,b:0,c:0},
                        {l:'Ayurveda Gewinnspiel',a:0,b:0,c:0}
                    ]
                },
                abonnements: {
                    items: [
                        {l:'manager magazin',a:0,b:0},
                        {l:'Expert Flyer',a:0,b:0}
                    ]
                },
                status: {
                    items: [
                        {l:'GHA Titanium',a:0,b:0},
                        {l:'GHA Platinum',a:0,b:0},
                        {l:'Hotmiles Platinum',a:0,b:0},
                        {l:'Hotmiles Gold',a:0,b:0}
                    ]
                },
                newsletter: {
                    items: [
                        {l:'Bildplus',a:0,b:0,c:0},
                        {l:'GS Turk',a:0,b:0,c:0},
                        {l:'GS Angsana',a:0,b:0,c:0},
                        {l:'NW-P1',a:0,b:0,c:0},
                        {l:'BILD',a:0,b:0,c:0},
                        // {l:'App',a:0,b:0,c:0},
                        // {l:'Blog Popup',a:0,b:0,c:0}
                    ]
                },
                orders: {
                    total_a: 0,
                    total_b: 0,
                    total_c: 0,
                    total_d: 0,
                    items: [
                        {l:'Neukunde',a:0,b:0,c:0,d:0},
                        {l:'Ehemaliger Kunde',a:0,b:0,c:0,d:0},
                        {l:'Auto Verlangerung',a:0,b:0,c:0,d:0},
                        {l:'Verlangerung',a:0,b:0,c:0,d:0},
                        {l:'Upgrade',a:0,b:0,c:0,d:0}
                    ]
                },
                mails: {
                    items: [
                        {l:'Diamant',a:0,b:0},
                        {l:'Platin',a:0,b:0},
                        {l:'Gold',a:0,b:0},
                        {l:'Regular',a:0,b:0}
                    ]
                },
                miles: {
                    items: [
                        {l:'Lufthansa',a:0,b:0,c:0},
                        {l:'Emirates',a:0,b:0,c:0}
                    ]
                },
                updated_at: '2000-01-01'
            }
        }
    },
    created () {
        this.getAnalytics()
    },
    computed: {
        ...mapGetters(['getUserData']),
        userData () {
            return this.getUserData
        }
    },
    methods: {
        refreshData () {
            this.loading = true;
            this.refresh = true;
            this.getAnalytics()
        },
        percent ( v, t ) {
            return ((v/t)*100).toFixed(2)+'%';
        },
        customerTable ( data ) {
            let grandTotal = _.sumBy(data, 'total');
            _.forEach( data, (o, k) => {
                if (o.type === 4) {
                    this.analytics.customer.items[0].h = o.mtd;
                    this.analytics.customer.items[0].t = o.total;
                    this.analytics.customer.items[0].s = this.percent( o.total, grandTotal );
                    this.analytics.customer.items[0].a = o.ony;
                    this.analytics.customer.items[0].b = o.twy;
                    this.analytics.customer.items[0].c = o.thy;
                    this.analytics.cancellation.items[0].v = this.percent( o.manual, o.total );
                } else if (o.type === 3) {
                    this.analytics.customer.items[1].h = o.mtd;
                    this.analytics.customer.items[1].t = o.total;
                    this.analytics.customer.items[1].s = this.percent( o.total, grandTotal );
                    this.analytics.customer.items[1].a = o.ony;
                    this.analytics.customer.items[1].b = o.twy;
                    this.analytics.customer.items[1].c = o.thy;
                    this.analytics.cancellation.items[1].v = this.percent( o.manual, o.total );
                } else if (o.type === 2) {
                    this.analytics.customer.items[2].h = o.mtd;
                    this.analytics.customer.items[2].t = o.total;
                    this.analytics.customer.items[2].s = this.percent( o.total, grandTotal );
                    this.analytics.customer.items[2].a = o.ony;
                    this.analytics.customer.items[2].b = o.twy;
                    this.analytics.customer.items[2].c = o.thy;
                    this.analytics.cancellation.items[2].v = this.percent( o.manual, o.total );
                } else if (o.type === 5) {
                    this.analytics.customer.items[3].h = o.mtd;
                    this.analytics.customer.items[3].t = o.total;
                    this.analytics.customer.items[3].s = this.percent( o.total, grandTotal );
                    this.analytics.customer.items[3].a = o.ony;
                    this.analytics.customer.items[3].b = o.twy;
                    this.analytics.customer.items[3].c = o.thy;
                }
                this.analytics.customer.total_t += o.total;
                this.analytics.customer.total_h += o.mtd;
                this.analytics.customer.total_a += o.ony;
                this.analytics.customer.total_b += o.twy;
                this.analytics.customer.total_c += o.thy;
            } )
        },
        anfragenTable ( data ) {
            _.forEach( data, (o, i) => {
                this.analytics.anfragen.items[i - 1].v = o;
            } )
        },
        wissenTable ( data ) {
            this.analytics.anfragen.items[3].v = data[5].open;
        },
        gewinnspielTable ( data ) {
            _.forEach( data, (o, i) => {
                this.analytics.gewinnspiel.items[i].a = o.total;
            } )
        },
        abonnementsTable ( data ) {
            _.forEach( data, (o) => {
                if (o.type === 2) {
                    this.analytics.abonnements.items[0].a = o.open;
                    this.analytics.abonnements.items[0].b = o.ytd;
                } else if (o.type === 8) {
                    this.analytics.abonnements.items[1].a = o.open;
                    this.analytics.abonnements.items[1].b = o.ytd;
                }
            } )
        },
        statusTable ( data ) {
            _.forEach( data, (o) => {
                if ( o.type === 3 ) {
                    this.analytics.status.items[0].a = o.mtd;
                    this.analytics.status.items[0].b = o.ytd;
                } else if ( o.type === 4 ) {
                    this.analytics.status.items[1].a = o.mtd;
                    this.analytics.status.items[1].b = o.ytd;
                } else if ( o.type === 5 ) {
                    this.analytics.status.items[2].a = o.mtd;
                    this.analytics.status.items[2].b = o.ytd;
                } else if ( o.type === 6 ) {
                    this.analytics.status.items[3].a = o.mtd;
                    this.analytics.status.items[3].b = o.ytd;
                }
            } )
        },
        newsletterTable ( data ) {
            data = _.drop( data, 5 );
            _.forEach( data, (o, i) => {
                this.analytics.newsletter.items[i].a = o.today;
                this.analytics.newsletter.items[i].b = o.mtd;
                this.analytics.newsletter.items[i].c = o.ytd;
            } )
        },
        mailsTable ( data ) {
            _.forEach( data, (o, i) => {
                this.analytics.mails.items[i].a = o.latest;
                this.analytics.mails.items[i].b = o.average;
            } )
        },
        milesTable ( data ) {
            _.forEach( data, (o, i) => {
                this.analytics.miles.items[i].a = o.today;
                this.analytics.miles.items[i].b = o.mtd;
                this.analytics.miles.items[i].c = o.ytd;
            } )
        },
        orderTable ( data ) {
            _.forEach( data, (i) => {
                if ( i.order_status === 1 ) {
                    this.analytics.order.total_a++
                    if (i.order_type === 1) {
                        this.analytics.order.items[0].a++
                    } else if (i.order_type === 2) {
                        this.analytics.order.items[1].a++
                    } else if (i.order_type === 3) {
                        this.analytics.order.items[2].a++
                    }
                }
                if ( i.order_status === 2 ) {
                    this.analytics.order.total_b++
                    let p = JSON.parse( i.product );
                    if (i.order_type === 1) {
                        this.analytics.order.items[0].b++
                        if (p.title === 'GOLD') {
                            this.analytics.order_paid.items[0].a++
                            this.analytics.order_paid.total_a++
                        } else if (p.title === 'PLATIN') {
                            this.analytics.order_paid.items[0].b++
                            this.analytics.order_paid.total_b++
                        } else if (p.title === 'DIAMANT') {
                            this.analytics.order_paid.items[0].c++
                            this.analytics.order_paid.total_c++
                        }
                    } else if (i.order_type === 2) {
                        this.analytics.order.items[1].b++
                        if (p.title === 'GOLD') {
                            this.analytics.order_paid.items[1].a++
                            this.analytics.order_paid.total_a++
                        } else if (p.title === 'PLATIN') {
                            this.analytics.order_paid.items[1].b++
                            this.analytics.order_paid.total_b++
                        } else if (p.title === 'DIAMANT') {
                            this.analytics.order_paid.items[1].c++
                            this.analytics.order_paid.total_c++
                        }
                    } else if (i.order_type === 3) {
                        this.analytics.order.items[2].b++
                        if (p.title === 'GOLD') {
                            this.analytics.order_paid.items[2].a++
                            this.analytics.order_paid.total_a++
                        } else if (p.title === 'PLATIN') {
                            this.analytics.order_paid.items[2].b++
                            this.analytics.order_paid.total_b++
                        } else if (p.title === 'DIAMANT') {
                            this.analytics.order_paid.items[2].c++
                            this.analytics.order_paid.total_c++
                        }
                    }   
                }
            } )
        },
        async getAnalytics () {
            let url = 'analytics'+(this.refresh ? '?query={"refresh":true}' : '');
            await db.list(url).then( response => {
                this.loading = false;
                let data = response.data;
                this.analytics.updated_at = data.updated_at;
                this.analytics.blocked = data.blocked;
                this.customerTable( data.customer );
                this.anfragenTable( data.support );
                this.wissenTable( data.enquiry );
                this.abonnementsTable( data.enquiry );
                this.statusTable( data.enquiry );
                this.gewinnspielTable( data.gewinnspiel );
                this.newsletterTable( data.newsletter );
                let ta = [
                    {latest:70,average:75},
                    {latest:64,average:69},
                    {latest:47,average:41},
                    {latest:42,average:42}
                ];
                this.mailsTable( ta );
                let tb = [
                    {today:0,mtd:0,ytd:405},
                    {today:0,mtd:0,ytd:184}
                ];
                this.milesTable( tb );
                this.orderTable( data.order );
            }).catch((error) => {
                console.log(error)
            });
        }
    }
}
</script>

<style scoped lang="scss">
.update-status {
    position: absolute;
    right: 15px;
    top: -28px;
}
.iq-card-body{
    flex: unset;
}
.table-borderless {
    border: 2px solid #f2edff;
    border-radius: 8px;
    width: 100%;
    margin: 0 auto;
    clear: both;
    border-collapse: separate;
    border-spacing: 0;
    overflow: hidden;
    thead {
        background: #f2edff;
    }
    tfoot {
        td {
            border-top: 1px solid #f2edff;
            font-weight: bold;
        }
    }
    tr {
        td, th {
            border-bottom: 1px solid #f2edff;
            vertical-align: middle;
            text-align: center;
            padding: .5rem;
            &:first-child {
                text-align: left;
            }
        }
        &:last-child {
            td {
                border-bottom: 0;
            }
        }
    }
}
</style>
