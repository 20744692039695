import db from '../../services/db'

export default {
    state: {
        list: [],
        data: {}
    },
    getters: {
        getTemplates (state, getters) {
            return state.list;
        },
        getTemplate (state, getters) {
            return state.data;
        }
    },
    actions: {
        fetchTemplates ({commit}, payload) {
            db.list('template', JSON.stringify( payload )).then((response) => {
                let data = response.data;
                commit('setTemplates', data.data)
            });
        },
        async fetchTemplate ({commit}, id) {
            await db.read('template', id).then((response) => {
                let data = _.pick(response.data, ['title','description','content','label', 'premium']);
                commit('setTemplate', data)
            });
        },
        addTemplate ({commit}, payload) {
            db.create('template', payload);
        },
        updateTemplate ({commit}, {payload, id}) {
            db.update('template', payload, id);
        }
    },
    mutations: {
        setTemplates (state, data) {
            state.list = data
        },
        setTemplate (state, data) {
            state.data = data
        },
        deleteTemplate (state, index) {
            state.list.splice(index, 1);
        }
    }
};
