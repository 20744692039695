import db from '../../services/db'

export default {
    state: {
        data: [],
        detail: {}
    },
    getters: {
        getFormData : (state) => {
            return state.data;
        },
        getFormDetail : (state) => {
            return state.detail;
        },
        getFormDataByID : (state) => (id) => {
            return _.filter( state.data, ( item ) => { return (item.type === id) } )
        },
        getFormDataExpert : (state) => {
            return _.filter( state.data, ( item ) => { return (item.sub_type === 8 && item.type === 2) } )
        },
        getFormDataGhaT : (state) => {
            return _.filter( state.data, ( item ) => { return (item.status === true && item.sub_type === 3 && item.type === 2) } )
        },
        getFormDataGhaP : (state) => {
            return _.filter( state.data, ( item ) => { return (item.status === true && item.sub_type === 4 && item.type === 2) } )
        },
        getFormDataManagerMagazine : (state) => { // MANAGER MAGAZINE 1
            return _.filter( state.data, ( item ) => { return (item.status === true && item.sub_type === 2 && item.type === 2) } )
        },
        getFormDataHotP : (state) => {
            return _.filter( state.data, ( item ) => { return (item.status === true && item.sub_type === 5 && item.type === 2) } )
        },
        getFormDataHotG : (state) => {
            return _.filter( state.data, ( item ) => { return (item.status === true && item.sub_type === 6 && item.type === 2) } )
        },
        getFormDataGha500 : (state) => {
            return _.filter( state.data, ( item ) => { return (item.sub_type === 4 || item.sub_type === 2) } )
        },
        getFormDataGha200 : (state) => {
            return _.filter( state.data, ( item ) => { return (item.sub_type === 3 || item.sub_type === 1) } )
        },

        getFormDataKN : (state) => {
            return _.filter( state.data, ( item ) => { return (item.status === true && item.sub_type === 7 && item.type === 1) } )
        },

        getFormDataGha : (state) => {
            
            return _.filter( state.data, ( item ) => { return (item.status === true && item.type === 4 && (item.sub_type === 24 || item.sub_type === 25 || item.sub_type === 26 || item.sub_type === 27 || item.sub_type === 29 || item.sub_type === 30 || item.sub_type === 31 || item.sub_type === 32 || item.sub_type === 36) ) } )
        },
        
        getFormDataIhrBonus : (state) => {
            return _.filter( state.data, ( item ) => { return (item.type === 4) } )
        },
        getFormDataJumeirah : (state) => {
            return _.filter( state.data, ( item ) => { return (item.status === true && item.type === 4 && (item.sub_type === 24 || item.sub_type === 25 || item.sub_type === 28 || item.sub_type === 29 || item.sub_type === 30 || item.sub_type === 33 )) } )
        },
        getFormDataHotelManual : (state) => {
            return _.filter( state.data, ( item ) => { return (item.type === 5) } )
        },
        getFormDataHotelVip : (state) => {
            return _.filter( state.data, ( item ) => { return (item.type === 5) } )
        },
        getFormDataHonCircle : (state) => {
            return _.filter( state.data, ( item ) => { return (item.status === true && item.sub_type === 9 && item.type === 2) } )
        },
        getFormDataAeroplan : (state) => {
            return _.filter( state.data, ( item ) => { return (item.status === true && item.sub_type === 10 && item.type === 2) } )
        }
    },
    actions: {
        fetchFormData ({commit}, payload = {}) {
            // HAVE TO FIX THE ISSUE, IF NOT PASSING TYPE OR ANY OTHER KEY TO FILTER -- THE FIELDS ARE NOT FETCHING DATA -- 22 AUG 2024
            let fields = "k_user,sub_type,updated_at,meta,user_id,success,success_at,exported_at,created_at,k_type,k_status,mailed_at";
            db.list('enquiry',JSON.stringify( payload ) , fields , false).then((response) => {
           //     db.list('enquiry',JSON.stringify( payload ) ).then((response) => {
                let data = [];
                _.forEach( response.data.data, function ( v, k ) {
                    v.user = v.user ? JSON.parse( v.user ) : '';
                    v.meta = v.meta ? JSON.parse( v.meta ) : '';
                    if (v.meta.form_data) {
                        try {
                            // First parse to convert the JSON string into an object
                            let parsedFormData = JSON.parse(v.meta.form_data);
            
                            // If form_data itself is a stringified JSON, parse it again
                            if (typeof parsedFormData === 'string') {
                                v.meta.form_data = JSON.parse(parsedFormData);
                            } else {
                                v.meta.form_data = parsedFormData;
                            }
            
                          //  console.log(v.meta.form_data);
                        } catch (e) {
                          //  console.error('Error parsing form_data:', e);
                        }
                    }
                    v.mail = v.mail ? JSON.parse( v.mail ) : '';
                    data.push( v );
                } )
                commit('setFormData', data)
            }).catch((error) => {
                console.log(error)
            });
        },
        async fetchFormDetail ({commit}, id) {
            await db.read('enquiry', id).then((response) => {
                let data = response.data;
                data.user = data.user ? JSON.parse( data.user ) : '';
                data.meta = data.meta ? JSON.parse( data.meta ) : '';
                commit('setFormDetail', data)
            });
        },
    },
    mutations: {
        setFormData (state, data) {
            state.data = data
        },
        setFormDetail (state, data) {
            state.detail = data
        },
        deleteEnquiry (state, id) {
            let index = state.data.findIndex(i => i._id===id);
            state.data.splice(index, 1);
        }
    }
};
