<template>
    <div v-if="editorShow">
        <trumbowyg v-model="editorContent" :config="editorConfig" @tbw-change="emitContent( name )" :name="name" />
        <library @attached="getAttachedImage" />
    </div>
</template>

<script>
    import Library from '../../views/Media/Library'
    import Trumbowyg from 'vue-trumbowyg';
    import 'trumbowyg/dist/ui/trumbowyg.css';
    import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css';
    import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.min.js';
    // import 'trumbowyg/dist/plugins/emoji/ui/trumbowyg.emoji.min.css';
    // import 'trumbowyg/dist/plugins/emoji/trumbowyg.emoji.min.js';
    import 'trumbowyg/dist/plugins/pasteembed/trumbowyg.pasteembed.min.js';
    import 'trumbowyg/dist/plugins/pasteimage/trumbowyg.pasteimage.min.js';
    // import 'trumbowyg/dist/plugins/table/ui/trumbowyg.table.min.css';
    //import 'trumbowyg/dist/plugins/table/trumbowyg.table.min.js';
    import 'trumbowyg/dist/plugins/upload/trumbowyg.upload.min.js';
    import 'trumbowyg/dist/plugins/template/trumbowyg.template.min.js';
    import 'trumbowyg/dist/plugins/noembed/trumbowyg.noembed.min.js';
    import 'trumbowyg/dist/plugins/fontsize/trumbowyg.fontsize.min.js';
    import {map} from 'lodash'
    import db from '../../services/db'

    export default {
        name: 'Editor',
        data () {
            return {
                upload_ref: '',
                editorShow: false,
                editorConfig: {
                    btnsDef: {
                        mediaLibrary: {
                            fn: this.showMediaLibrary,
                            title: 'Media Library',
                            ico: 'upload'
                        }
                    },
                    btns: [
                        ['formatting'],
                        ['fontsize'],
                        ['undo', 'redo'],
                        ['strong', 'em', 'del'],
                        ['superscript', 'subscript'],
                        ['link'],
                        ['unorderedList', 'orderedList'],
                        ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                        ['insertImage','mediaLibrary','noembed'],
                        ['horizontalRule'],
                        ['foreColor', 'backColor'],
                        ['removeformat', 'viewHTML'],
                        ['fullscreen']
                    ],
                    plugins: {
                        upload: {
                            serverPath: BACKEND_URL + '/media',
                            fileFieldName: 'customFile',
                            urlPropertyName: 'url'
                        },
                        templates: null
                    }
                },
                content: ''
            }
        },
        components: {
            Trumbowyg,
            Library
        },
        props: {
            name: {
                type: String,
                default: 'content'
            },
            data: {
                type: String,
                default: ''
            },
            email: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            editorContent: {
                get () {
                    return this.data;
                },
                set ( newValue ) {
                    this.content = newValue;
                }
            }
        },
        mounted () {
            if ( this.email ) {
                this.fetchTemplates();
            } else {
                this.editorShow = true;
            }
        },
        methods: {
            fetchTemplates: function () {
                db.list('template').then((response) => {
                    this.$nextTick(function(){
                        let html = map( response.data.data, function ( o ) {
                            let a = {};
                            a.name = o.title;
                            a.html = o.content;
                            return a;
                        } );
                        this.editorConfig.btns.unshift( ['template'] );
                        this.editorConfig.plugins.templates = html;
                        this.editorShow = true;
                    });
                });
            },
            showMediaLibrary: function () {
                this.upload_ref = 'editor';
                this.$bvModal.show('modal-media-library');
            },
            getAttachedImage: function ( id, url ) {
                if ( this.upload_ref === 'editor' ) {
                    document.execCommand('insertImage', false, (url || ""));
                } else {
                    this.$emit('attached', id, url);
                }
            },
            emitContent (event, ref) {
                this.$emit('content', this.content)
            }
        }
    }
</script>

<style lang="scss">
.trumbowyg-box,
.trumbowyg-editor {
    min-height: 200px;
    &:not(.trumbowyg-fullscreen) {
        max-height: 800px;
    }
}
</style>
