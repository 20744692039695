import db from '../../services/db'

export default {
    state: {
        list: [],
        data: {}
    },
    getters: {
        getPosts (state, getters) {
            return state.list;
        },
        getPost (state, getters) {
            return state.data;
        }
    },
    actions: {
        fetchPosts ({commit}, payload) {
            db.list('press', JSON.stringify( payload )).then((response) => {
                let data = response.data;
                commit('setPosts', data.data)
            });
        },
        async fetchPost ({commit}, id) {
            await db.read('press', id).then((response) => {
                commit('setPost', response.data)
            });
        },
        addPost ({commit}, payload) {
            db.create('press', payload);
        },
        updatePost ({commit}, {payload, id}) {
            db.update('press', payload, id);
        }
    },
    mutations: {
        setPosts (state, data) {
            state.list = data
        },
        setPost (state, data) {
            state.data = data
        },
        deletePost (state, index) {
            state.list.splice(index, 1);
        }
    }
};
