import moment from "moment";
import domtoimage from "dom-to-image";

export function generateID (value = 8) {
    let chars = "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ",
        string = _.sampleSize(chars, value);
    return string.join("");
}

export function formatDate(value) {
    if ( value ) {
        return moment(value).format('DD/MM/YYYY');
    }
}

export function formatDate2(value) {
    if ( value ) {
        return moment(value).format('DD.MM.YYYY');
    }
}

export function getDay(value = getTodayDate()) {
    return moment(value).format("DD");
}

export function getMonth(value = getTodayDate(), text = false) {
    let format = (text) ? 'MMMM' : 'M';
    return moment(value).format(format);
}

export function getYear(value = getTodayDate(), text = false) {
    let format = (text) ? 'YYYY' : 'Y';
    return moment(value).format(format);
}

export function getQuarter(value = getTodayDate()) {
    return moment(value).quarter();
}

export function getTodayDate( format = false ) {
    return (format) ? moment().format('YYYY-MM-DD') : moment().toDate();
}

export function getCurrencyCode (value = 1) {
    return _.get(dataJSON, String('general.currency.'+value));
}

export function encryptData ( data ) {
    const encryption = require('laravel-encryption');
    let key = 'base64:FmW3eCplHvZqWAEG5CBMxEDyCzCdbkfWYm4dq1zKif4=';
    let encryptor = new encryption.Encrypter(Buffer.from(key.substr(7), 'base64'), 'AES-256-CBC');
    return encryptor.encrypt(data);
}

export function decryptData ( data ) {
    const encryption = require('laravel-encryption');
    let key = 'base64:FmW3eCplHvZqWAEG5CBMxEDyCzCdbkfWYm4dq1zKif4=';
    let encryptor = new encryption.Encrypter(Buffer.from(key.substr(7), 'base64'), 'AES-256-CBC');
    return encryptor.decrypt( data );
}

export function generatePDF ( el, filename = 'file' ) {
    domtoimage
        .toPng( el, { width: 794, height: 1123, background: 'white', quality: 1.0 } )
        .then(function (dataUrl) {
            let img = new Image();
            img.src = dataUrl;
            let doc = new jsPDF('p', 'px', 'a4');
            doc.addImage(img, 'image/png', 0, 0);
            doc.save( filename + '.pdf' );
        })
        .catch(function (error) {
            console.error('Oops, something went wrong!', error);
        });
}
export function encodeEmail(email) {
    return btoa(email);
}

export function decodeEmail(encodedEmail) {
       return atob(encodedEmail);
}