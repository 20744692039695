import db from '../../services/db'

export default {
    state: {
        detail: {
            user: {}
        },
        list: [],
        listGW: []
    },
    getters: {
        getSubscriberDetail : (state) => {
            return state.detail;
        },
        getSubscribersList : (state) => {
            return state.list
        },
        getSubscribersListGW : (state) => {
            return state.listGW
        }
    },
    actions: {
        fetchSubscribersList ({commit}, payload) {
            db.list('subscribe', JSON.stringify( payload )).then((response) => {
                const filteredList = _.filter(response.data.data, item => item.type === 1 && item.newsletter === true);
                commit('setSubscribersList', filteredList)
                commit('setSubscribersListGW', _.filter(response.data.data, ['type', 2]))
            }).catch((error) => {
                console.log(error)
            });
        },
        async fetchSubscriberDetail ({commit}, id) {
            await db.read('subscribe', id).then((response) => {
                let data = response.data;
                data.user = JSON.parse( data.user );
                commit('setSubscriberDetail', data)
            }).catch((error) => {
                console.log(error)
            });
        }
    },
    mutations: {
        setSubscribersList (state, data) {
            state.list = data
        },
        setSubscribersListGW (state, data) {
            state.listGW = data
        },
        setSubscriberDetail (state, data) {
            state.detail = data
        },
        deleteSubscriber (state, id) {
            let index = state.list.findIndex(i => i._id===id);
            state.list.splice(index, 1);
        }
    }
};
