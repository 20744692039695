<template>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form novalidate @submit.prevent="handleSubmit( Login )">
            <ValidationProvider vid="email" name="E-mail" rules="required|email" v-slot="{ errors }">
                <div class="form-group">
                    <label for="emailInput">Email</label>
                    <input type="email" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                           id="emailInput" aria-describedby="emailHelp"
                           v-model="user.email" placeholder="Enter email" required>
                    <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                    </div>
                </div>
            </ValidationProvider>
            <ValidationProvider vid="password" name="Password" rules="required" v-slot="{ errors }">
                <div class="form-group">
                    <label for="passwordInput">Password</label>
                    <input type="password" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                           id="passwordInput"
                           v-model="user.password" placeholder="Enter password" required>
                    <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                    </div>
                </div>
            </ValidationProvider>
            <div class="d-inline-block w-100 text-right">
                <submit label="Sign in" :loading="loading" />
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
    import auth from '../../services/auth'
    import Submit from '../Common/Submit'
    import {vito} from "../../config/pluginInit";

    export default {
        name: 'SignInForm',
        components: {
            Submit
        },
        data: () => ({
            loading: false,
            user: {
                email: '',
                password: ''
            }
        }),
        mounted () {},
        computed: {},
        methods: {
            Login () {
                this.loading = true;
                auth.login(this.user).then((response) => {
                    sessionStorage.setItem( 'user', JSON.stringify( response.data ) )
                    vito.showSnackbar('success', 'Logged in successfully!');
                    this.$router.push({ name: 'dashboard.home' });
                }).catch((error) => {
                    let responseData = error.response.data;
                    if ( responseData.error ) {
                        vito.showSnackbar('error', responseData.error );
                    } else {
                        this.$refs.form.setErrors( responseData.errors )
                    }
                }).finally(() => {
                    this.loading = false
                });
            }
        }
    }
</script>
