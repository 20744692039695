<template>
    <ul class="navbar-list">
        <li>
            <a href="#" class="search-toggle iq-waves-effect d-flex align-items-center">
                <img v-if="avatarShow" :src="avatar" class="img-fluid rounded mr-3" alt="user">
                <div class="caption">
                    <h6 class="mb-0 line-height">{{ name }}</h6>
                    <!-- <span class="font-size-12">{{ role }}</span> -->
                </div>
            </a>
            <div class="iq-sub-dropdown iq-user-dropdown">
                <div class="iq-card shadow-none m-0">
                    <div class="iq-card-body p-0 ">
                        <a v-if="profile" :href="profile" class="iq-sub-card iq-bg-primary-success-hover">
                            <div class="media align-items-center">
                                <div class="rounded iq-card-icon iq-bg-success">
                                    <i class="ri-profile-line" />
                                </div>
                                <div class="media-body ml-3">
                                    <h6 class="mb-0 ">View / Edit Profile</h6>
                                    <p class="mb-0 font-size-12">View or modify your profile detail</p>
                                </div>
                            </div>
                        </a>
                        <div class="d-inline-block w-100 text-center p-3">
                            <a class="iq-bg-danger iq-sign-btn" href="javascript:void(0)" @click="logout" role="button">Sign Out <i class="ri-login-box-line ml-2"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</template>

<script>
import {mapActions} from 'vuex'
export default {
    name: "User",
    props: {
        user: {}
    },
    data () {
        return {
            profile: '',
            name: '',
            role: '',
            avatar: '',
            avatarShow: true
        }
    },
    computed: {},
    created () {},
    mounted () {
        let name = this.user.name.split(' '),
            a = name[0].charAt(0),
            b = (name[1]) ? name[1].charAt(0) : '';
        this.name = a + b
        this.role = this.user.role.name
        this.avatar = 'https://dummyimage.com/100x100/478BCA/FFFFFF?text=' + a + b;
        this.profile = '/user/' + this.user.id;
    },
    methods: {
        ...mapActions(['deleteUserData']),
        logout () {
            this.deleteUserData().then(() => {
                location.reload()
            })
        }
    }
}
</script>
