<template>
    <div class="uploader">

        <div class="upload">
            <div class="table-responsive">
                <table class="table table-sm" cellpadding="0" cellspacing="0">
                    <thead>
                    <tr>
                        <th>Thumb</th>
                        <th>File</th>
                        <th>Type</th>
                        <th>Size</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="!files.length">
                        <td colspan="6" class="drop-area-wrap">
                            <div class="drop-area">
                                <h4>Drop files anywhere to upload<br/><small>or</small></h4>
                                <label :for="config.name" class="btn btn-lg btn-primary">Select Files</label>
                                <span v-show="$refs.upload && $refs.upload.dropActive">Drag and drop here for upload</span>
                            </div>
                        </td>
                    </tr>
                    <tr v-for="(file, index) in files" :key="file.id">
                        <td class="fileThumb">
                            <img v-if="file.thumb" :src="file.thumb" :alt="file.name" />
                            <img v-else-if="file.type === 'application/pdf'" :src="pdfThumb" :alt="file.name" />
                            <img v-else :src="noThumb" alt="no thumbnail" />
                        </td>
                        <td>
                            <div class="filename">
                                Name: {{file.name}}<br>
                                <span v-if="file.data.name">Alt: {{file.data.name}}</span><br>
                                <span v-if="file.data.category">Category: {{file.data.category}}</span>
                            </div>
                            <div class="progress" v-if="file.active || file.progress !== '0.00'">
                                <div :class="{'progress-bar': true, 'progress-bar-striped': true, 'bg-danger': file.error, 'progress-bar-animated': file.active}" role="progressbar" :style="{width: file.progress + '%'}">{{file.progress}}%</div>
                            </div>
                        </td>
                        <td>{{file.type}}</td>
                        <td>{{file.size | formatSize}}</td>
                        <td>
                            <span v-if="file.error"><b-badge variant="danger">{{file.error}}</b-badge></span>
                            <span v-else-if="file.success"><b-badge variant="success">Success</b-badge></span>
                            <span v-else-if="file.active"><b-badge variant="info">Active</b-badge></span>
                        </td>
                        <td>
                            <div class="user-actions">
                                <button title="Edit" @click="file.active || file.success ? false :  onEditFile(file)" class="btn btn-default text-success">
                                    <i class="ri-edit-2-fill" />
                                </button>
                                <button title="Retry" v-if="file.error && $refs.upload.features.html5" @click.prevent="$refs.upload.update(file, {active: true, error: '', progress: '0.00'})" class="btn btn-default text-info">
                                    <i class="ri-restart-fill" />
                                </button>
                                <button title="Remove" @click.prevent="onRemoveFile(file)" class="btn btn-default text-danger">
                                    <i class="ri-delete-bin-fill" />
                                </button>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="foot">
                <file-upload class="btn btn-primary"
                             :post-action="config.url"
                             :extensions="config.extensions"
                             :accept="config.accept"
                             :multiple="true"
                             :size="0"
                             :maximum="5"
                             :headers="config.headers"
                             :data="config.data"
                             :drop="true"
                             :drop-directory="false"
                             :add-index="1"
                             v-model="files"
                             :name="config.name"
                             @input-filter="inputFilter"
                             @input-file="inputFile"
                             ref="upload">Add More Files</file-upload>
                <button type="button" class="btn btn-success" v-if="!$refs.upload || !$refs.upload.active" @click.prevent="$refs.upload.active = true">Start Upload</button>
                <button type="button" class="btn btn-danger"  v-else @click.prevent="$refs.upload.active = false">Stop Upload</button>
            </div>
        </div>

        <div :class="{modal: true, fade: true, show: editFile.show}" id="modal-edit-file" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Edit File</h5>
                        <button type="button" class="close"  @click.prevent="editFile.show = false">
                            <span>&times;</span>
                        </button>
                    </div>
                    <form @submit.prevent="onSaveFileEdits">
                        <div class="modal-body">
                            <div class="form-group">
                                <label for="name">ALT Text:</label>
                                <input type="text" class="form-control" required id="name" v-model="editFile.name">
                            </div>
                            <div class="form-group" v-if="editFile.show && editFile.blob && editFile.type && editFile.type.substr(0, 6) === 'image/'">
                                <label>Image: </label>
                                <div class="edit-image">
                                    <img :src="editFile.blob" ref="editImage"  alt="image edit " />
                                </div>
                            </div>
<!--                            <b-form-group label="Categories:" label-for="categories">-->
<!--                                <b-form-select plain v-model="editFile.category"-->
<!--                                               :options="categories" value-field="_id"-->
<!--                                               text-field="title"/>-->
<!--                            </b-form-group>-->
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import FileUpload from 'vue-upload-component'

    export default {
        name: 'LibraryUploader',
        props: {
            folder: {
                type: String,
                default: 'media'
            }
        },
        components: {
            FileUpload
        },
        mounted () {},
        data () {
            return {
                files: [],
                pdfThumb: APP_URL + '/images/icon-file-pdf.png',
                noThumb: APP_URL + '/images/icon-file-error.png',
                config: {
                    name: 'customFile',
                    url: BACKEND_URL + '/media',
                    accept: 'image/gif,image/jpeg,image/png,image/webp,application/pdf',
                    headers: {},
                    data: {
                        folder: this.folder
                    }
                },
                categories: [],
                editFile: {
                    show: false,
                    name: ''
                }
            }
        },
        computed: {},
        created () {
            this.page_title = this.$route.meta.name;
        },
        watch: {
            'editFile.show'(newValue, oldValue) {
                if (!newValue && oldValue) {
                    this.$refs.upload.update(this.editFile.id, { error: this.editFile.error || '' })
                }
            }
        },
        methods: {
            // fetchCategories () {
            //     db.list('cms/category', JSON.stringify({ taxonomy: 5 })).then((response) => {
            //         this.categories = response.data.data;
            //     }).catch((error) => {
            //         console.log(error)
            //     });
            // },
            inputFilter(newFile, oldFile, prevent) {
                if (newFile && !oldFile) {
                    if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
                        return prevent()
                    }
                    if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
                        return prevent()
                    }
                }
                if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
                    newFile.blob = '';
                    let URL = window.URL || window.webkitURL;
                    if (URL && URL.createObjectURL) {
                        newFile.blob = URL.createObjectURL(newFile.file)
                    }
                    newFile.thumb = '';
                    if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
                        newFile.thumb = newFile.blob
                    }
                }
            },
            inputFile(newFile, oldFile) {
                if (newFile && oldFile) {
                    if (newFile.active && !oldFile.active) {
                        if (newFile.size >= 0 && this.minSize > 0 && newFile.size < this.minSize) {
                            this.$refs.upload.update(newFile, { error: 'size' })
                        }
                    }
                    if (newFile.progress !== oldFile.progress) {
                    }
                    if (newFile.error && !oldFile.error) {
                    }
                    if (newFile.success && !oldFile.success) {
                    }
                }
                if (!newFile && oldFile) {
                    if (oldFile.success && oldFile.response.id) {
                    }
                }
                if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                    if (this.uploadAuto && !this.$refs.upload.active) {
                        this.$refs.upload.active = true
                    }
                }
            },
            onEditFile(file) {
                // this.fetchCategories();
                this.editFile = { ...file, show: true };
                this.$refs.upload.update(file, { error: 'edit' })
            },
            onRemoveFile(file) {
                this.$refs.upload.remove(file);
            },
            onSaveFileEdits() {
                let data = {
                    data: {
                        name: this.editFile.name,
                        //category: this.editFile.category
                    }
                };
                this.$refs.upload.update(this.editFile.id, data);
                this.editFile.error = '';
                this.editFile.show = false
            },
        }
    }
</script>

<style type="text/css" scoped>
    .uploader .modal .edit-image {
        text-align: center;
        max-height: 500px;
        overflow: auto;
    }
    .uploader .modal .edit-image img {
        max-width: 100%;
    }
    .uploader .modal.show {
        display: block;
    }

    .uploader .drop-active {
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        position: fixed;
        z-index: 9999;
        opacity: .6;
        text-align: center;
        background: #000;
    }
    .uploader .drop-active h3 {
        margin: -.5em 0 0;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 40px;
        color: #fff;
        padding: 0;
    }

    .uploader table {
        min-height: 400px;
        table-layout: fixed;
    }
    .uploader table th {
        border: none;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        padding: 5px;
        background: #dee2e6;
    }
    .uploader table td {
        border-top: none;
        border-bottom: 1px solid #dee2e6;
        vertical-align: middle;
    }
    .uploader table thead tr {
        height: 30px;
    }
    .uploader table tbody tr {
        height: 50px;
    }
    .uploader table .fileThumb img {
        height: 75px;
        width: 75px;
    }
    .uploader table .drop-area-wrap {
        padding: 0;
        vertical-align: middle;
        background: #f1f1f1;
    }
    .uploader table .drop-area-wrap .drop-area {
        text-align: center;
        padding: 8rem 4rem;
    }
    .uploader .drop-area label {
        color: #ffffff;
    }

    .uploader .foot {
        padding: .5rem 0;
        text-align: right;
    }
</style>
