import axios from './index'

export default {
    create (endPoint, data) {
        let user = JSON.parse(sessionStorage.getItem('user')),
            config = {
                headers: {
                    'Authorization': `Bearer ${user.access_token}`
                }
            };
        return axios.post('/'+endPoint, data, config);
    },
    read (endPoint, id = null) {
        let user = JSON.parse(sessionStorage.getItem('user')),
            config = {
                headers: {
                    'Authorization': `Bearer ${user.access_token}`
                }
            };
        return axios.get('/'+endPoint+'/'+id, config);
    },
    list (endPoint, query = null, fields = null, page = null) {
        let user = JSON.parse(sessionStorage.getItem('user')),
            config = {
                headers: {
                    'Authorization': `Bearer ${user.access_token}`
                }
            };

        if(fields != null){
            if(query != null){
                return axios.get('/'+endPoint+'?query='+query+'&fields='+fields, config);
            }
            else{
                return axios.get('/'+endPoint+'?query={}&?fields='+fields, config);
            }
        }

        if(page != null){
            if(query != null){
                return axios.get('/'+endPoint+'?query='+query+'&page='+page, config);
            }
            else{
                return axios.get('/'+endPoint+'?page='+page, config);
            }
        }
        
       else if ( query == null ) {
            return axios.get('/'+endPoint, config);
        }
        else if(fields == null){
            return axios.get('/'+endPoint+'?query='+query, config);
        }
        else if(page == null){
            return axios.get('/'+endPoint+'?query='+query, config);
        }
        else{
            return axios.get('/'+endPoint+'?query='+query+'&fields='+fields, config);
        }
    },
    update (endPoint, data, id) {
        let user = JSON.parse(sessionStorage.getItem('user')),
            config = {
                headers: {
                    'Authorization': `Bearer ${user.access_token}`
                }
            };
        data = _.omit(data, ['created_at','updated_at']);
        return axios.put('/'+endPoint+'/'+id, data, config);
    },
    delete (endPoint, id) {
        let user = JSON.parse(sessionStorage.getItem('user')),
            config = {
                headers: {
                    'Authorization': `Bearer ${user.access_token}`
                }
            };
        return axios.delete('/'+endPoint+'/'+id, config);
    },
}
