<template>
    <a v-if="url" :href="url" target="_blank" class="text-primary">
        <i class="ri-links-line" /> {{ label }}
    </a>
</template>

<script>
    import Vue from 'vue'

    export default Vue.extend({
        mixins: [
            {
                data () {},
                mounted () {},
                computed: {
                    url: function () {
                        return this.params.value;
                    },
                    label: function () {
                        return ( this.params.label ) ? this.params.label : this.params.value;
                    }
                },
                components: {},
                methods: {}
            }
        ]
    })
</script>
