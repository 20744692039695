import db from '../../services/db'

export default {
    state: {
        data: [],
        dataSpecific: [],
        dataRU: [],
        dataRC: [],
        dataBC: [],
        detail: {
            customer: {},
            meta: {}
        },
        royal_circle: {},
        credit_log: [],
        promotions: [],
        shipping_address: {},
        meta_id: null,
        customer_id: null,
        error: null
    },
    getters: {
        getData (state, getters) {
            return state.data;
        },
        getSpecificData (state, getters) {
            return state.dataSpecific;
        },
        getDataRU (state, getters) {
            return state.dataRU;
        },
        getDataRC (state, getters) {
            return state.dataRC;
        },
        getDataBC (state, getters) {
            return state.dataBC;
        },
        getDataDetail (state, getters) {
            return state.detail;
        },
        getShippingDetail (state, getters) {
            return state.shipping_address;
        },
        getRoyalCircle (state, getters) {
            return state.royal_circle;
        },
        getCreditsLog (state, getters) {
            return state.credit_log;
        },
        getMetaId (state, getters) {
            return state.meta_id;
        },
        getCustomerId (state, getters) {
            return state.customer_id;
        },
        getPromotions (state, getters) {
            return state.promotions;
        },
        getError (state, getters) {
            return state.error;
        }
    },
    actions: {
        fetchData ({commit}, payload) {
            db.list('customer',JSON.stringify({customer_type:1})).then((response) => {
                commit('setData', response.data.data)
            }).catch((error) => {
                commit('setError', error)
            });
        },
        fetchSpecificData ({commit}, fields) {
            db.list('customer',JSON.stringify({customer_type:1, initialload:fields.initialload? true: false}) , fields.fields, fields.initialload).then((response) => {
                commit('setSpecificData', response.data.data);
            }).catch((error) => {
                commit('setError', error)
            });
        },
        // registered users only - non paying users
        // fetchDataRU ({commit}, payload) {
        //     db.list('customer',JSON.stringify({customer_type:2})).then((response) => {
        //         commit('setDataRU', response.data.data)
        //     }).catch((error) => {
        //         commit('setError', error)
        //     });
        // },
        fetchDataRU ({commit}, fields) {
            db.list('customer',JSON.stringify({customer_type:2, initialload:fields.initialload? true: false}) , fields.fields, fields.initialload).then((response) => {
                commit('setDataRU', response.data.data);
            }).catch((error) => {
                commit('setError', error)
            });
        },
        fetchDataRC ({commit}, payload) {
            db.list('customer',JSON.stringify({royal_circle:1 , customer_type:1})).then((response) => {
                commit('setDataRC', response.data.data)
            }).catch((error) => {
                commit('setError', error)
            });
        },
        fetchDataBC ({commit}, payload) {
            db.list('customer',JSON.stringify({locked:1,status:1})).then((response) => {
                commit('setDataBC', response.data.data)
            }).catch((error) => {
                commit('setError', error)
            });
        },
        async fetchDataDetail ({commit}, id) {
            const response = await db.read('customer', id);
            commit('setDataDetail', response.data)
        }
    },
    mutations: {
        setData (state, data) {
            state.data = data
        },
        setSpecificData (state, data) {
            state.dataSpecific = data
        },
        setDataRU (state, data) {
            state.dataRU = data
        },
        setDataRC (state, data) {
            state.dataRC = data
        },
        setDataBC (state, data) {
            state.dataBC = data
        },
        setDataDetail (state, data) {
            state.detail.customer = _.pick(data, ['salutation','first_name','last_name','email','city','country','verified','auto_renew','founders_club','royal_circle','source','membership','membership_expiry','status','meta_id','newsletter_general','newsletter_premium', 'customer_no','created_at', 'address', 'address_id']);
            let metaData = _.pick(data.meta, ['title','dob','address','zip','company','phone','mobile','billing_address','shipping_address','royal_circle','credit_log','promos','newsletter_filters','note','referral_code','credit_earned','credit_used','credit_available','credit_knowledge','credit_referral','credit_affiliate','book','my_data','details']);
            if ( !_.isEmpty( data.meta.shipping_address ) ) {
                state.shipping_address = JSON.parse( data.meta.shipping_address );
            }
            if ( !_.isEmpty( data.meta.royal_circle ) ) {
                state.royal_circle = JSON.parse( data.meta.royal_circle );
            }
            if ( !_.isEmpty( data.meta.credit_log ) ) {
                state.credit_log = JSON.parse( data.meta.credit_log );
            }
            if ( !_.isEmpty( data.meta.promos ) ) {
                state.promotions = JSON.parse( data.meta.promos );
            }
            state.detail.meta = metaData;
            state.meta_id = data.meta_id;
            state.customer_id = data.id;
        },
        setError (state, data) {
            state.error = data;
        }
    }
};
