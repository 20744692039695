import Vue from 'vue'
import '@babel/polyfill'
import 'mutationobserver-shim'
import './Utils/fliter'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins'
import './registerServiceWorker'
import i18n from './i18n'

Vue.config.productionTip = false;

let vm = new Vue({
    router,
    i18n,
    store,
    render: h => h(App)
}).$mount('#app');

window.vm = vm;
