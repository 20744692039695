import Vue from 'vue'
import Vuex from 'vuex'
import event from './modules/event'
import form from './modules/form'
import customer from './modules/customer'
import newsletter from './modules/newsletter'
import promos from './modules/promos'
import notifications from './modules/notifications'
import support from './modules/support'
import templates from './modules/templates'
import press from './modules/press'
import booking from './modules/booking'
import subscribers from './modules/subscribers'
import publication from './modules/publication'
import user from './modules/user'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        event,
        form,
        customer,
        newsletter,
        promos,
        notifications,
        support,
        templates,
        press,
        booking,
        subscribers,
        publication,
        user
    },
    state: {
    },
    mutations: {
    },
    actions: {
    },
    getters: {
    },
    strict: false
});
