<template>
    <div class="user-actions" :id="params.data._id">
        <a :href="getFileURL" target="_blank" title="View File" class="btn btn-default text-primary">
            <i class="ri-eye-fill"/>
        </a>
<!--        <button title="Edit File" @click="openEditFile(params.data)" class="btn btn-default text-success">-->
<!--            <i class="ri-edit-2-fill"/>-->
<!--        </button>-->
        <button title="Copy URL" v-clipboard:copy="getFileURL" class="btn btn-default text-info"
                v-clipboard:success="onCopy">
            <i class="ri-file-copy-fill" />
        </button>
        <button title="Delete" @click="removeData(params.data._id)" class="btn btn-default text-danger">
            <i class="ri-delete-bin-2-fill"/>
        </button>
    </div>
</template>

<script>
    import Vue from 'vue'
    import db from '../../../services/db'
    import VueClipboard from 'vue-clipboard2'
    import { vito } from '../../../config/pluginInit'

    Vue.use( VueClipboard );

    export default Vue.extend({
        mixins: [
            {
                data () {
                    return {
                    }
                },
                components: {},
                mounted () {
                },
                computed: {
                    getFileURL () {
                        return CDN_URL_FULL + this.params.data.file;
                    }
                },
                methods: {
                    onCopy: function (e) {
                        vito.showSnackbar('success', 'URL is copied.');
                    },
                    // openEditFile ( data ) {
                    //     this.$parent.gridOptions.context.componentParent.openEditFile( data );
                    // },
                    removeData (id) {
                        db.delete('media', id).then((response) => {
                            vito.showSnackbar('success', 'Removed successfully.');
                            this.$parent.gridOptions.context.componentParent.refreshData();
                        }).catch((error) => {
                            vito.showSnackbar('error', error);
                        });
                    }
                }
            }
        ]
    })
</script>
