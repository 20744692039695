import auth from '../../services/auth'

export default {
    state: {
        data: {}
    },
    getters: {
        getUserData : (state, getters) => {
            return state.data;
        },
        isAdmin : (state, getters) => {
            return (state.data.role_id === 1 || state.data.role_id === 6);
        }
    },
    actions: {
        async fetchUserData ({commit}) {
            return await auth.self().then((response) => {
                commit('setUserData', response.data)
            }).catch((error) => {
                commit('deleteUserData');
                console.log(error)
            })
        },
        async deleteUserData ({commit}) {
            return await auth.logout().then((response) => {
                commit('deleteUserData')
            }).catch((error) => {
                console.log(error)
            })
        }
    },
    mutations: {
        setUserData (state, data) {
            state.data = data
            sessionStorage.setItem( 'user_data', JSON.stringify( data ) )
        },
        deleteUserData (state) {
            state.data = {}
            sessionStorage.removeItem('user');
            sessionStorage.removeItem('user_data');
        }
    }
};
