import db from '../../services/db'

export default {
    state: {
        list: []
    },
    getters: {
        getPromos (state, getters) {
            return state.list;
        }
    },
    actions: {
        fetchPromos ({commit}, payload) {
            db.list('app/promo', JSON.stringify( payload )).then((response) => {
                let data = response.data;
                commit('setPromos', data.data)
            }).catch((error) => {
                console.log(error)
            });
        }
    },
    mutations: {
        setPromos (state, data) {
            state.list = data
        },
        deletePromo (state, index) {
            state.list.splice(index, 1);
        }
    }
};
