<template>
    <div>
        <Loader />
        <b-container class="mt-5">
            <div class="text-center mb-4">
                <a class="sign-in-logo" href="#"><img :src="logo" class="img-fluid" alt="logo"></a>
            </div>
            <div class="row justify-content-md-center">
                <div class="col-sm-5">
                    <router-view />
                </div>
            </div>
        </b-container>
    </div>
</template>
<script>
    import Loader from '../components/common/Loader'
    import { vito } from '../config/pluginInit'

    export default {
        name: 'AuthLayout',
        components: {
            Loader
        },
        mounted () {
            vito.index()
        },
        data: () => ({
            logo: '../../../images/logo.png'
        }),
    }
</script>
