<template>
    <div>
        <b-modal :static="true" :lazy="true" hide-footer ref="modal-media-library" id="modal-media-library" size="xl" title="Media Library">
            <b-tabs pills content-class="mt-3" v-model="tabIndex">
                <b-tab title="Library" lazy>
                    <LibraryList :attachment="true" :folder="folder" @onSelect="addAttachment" />
                </b-tab>
                <b-tab title="Upload Files" lazy>
                    <LibraryUploader :folder="folder" />
                </b-tab>
            </b-tabs>
        </b-modal>
    </div>
</template>

<script>
    import LibraryList from "./Library/List";
    import LibraryUploader from "./Library/Uploader";

    export default {
        name: 'MediaLibrary',
        props: {
            folder: {
                type: String,
                default: 'media'
            },
            name: {
                type: String,
                default: 'featured'
            }
        },
        components: {
            LibraryList,
            LibraryUploader,
        },
        beforeMount () {},
        mounted () {},
        data () {
            return {
                tabIndex: 0
            }
        },
        computed: {},
        created () {},
        methods: {
            addAttachment: function ( selected ) {
                this.$bvModal.hide('modal-media-library');
                this.$emit('attached', selected[0].data._id, selected[0].data.file, selected[0].data.type);
            }
        }
    }
</script>
