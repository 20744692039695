import axios from './index'

export default {
    login (userData) {
        let data = {
            email: userData.email,
            password: userData.password
        };
        return axios.post('/auth/da/login', data);
    },
    self () {
        let user = JSON.parse(sessionStorage.getItem('user')),
            config = {
                headers: {
                    'Authorization': `Bearer ${user.access_token}`
                }
            };
        return axios.get('/auth/da/self', config);
    },
    logout () {
        let user = JSON.parse(sessionStorage.getItem('user')),
            config = {
                headers: {
                    'Authorization': `Bearer ${user.access_token}`
                }
            };
        return axios.post('/auth/da/logout', {}, config);
    },
    password (userData, id) {
        let data = {
            password: userData.password
        };
        return axios.put('/user/'+id, data);
    }
}
