import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);

function loadLocaleMessages () {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    });
    return messages
}

const dateTimeFormats = {
    'de-DE': {
        short: {
            year: 'numeric', month: 'numeric', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    }
};

const numberFormats = {
    'de-DE': {
        currency: {
            style: 'currency', currency: 'EUR', currencyDisplay: 'symbol', maximumFractionDigits: 2
        },
        percent: {
            style: 'percent', useGrouping: false
        },
        number: {
            style: 'decimal', useGrouping: true
        }
    }
};

export default new VueI18n({
    locale: process.env.APP_LOCALE || 'de-DE',
    messages: loadLocaleMessages(),
    numberFormats,
    dateTimeFormats
})
