<template>
    <div v-if="editorShow">
        <trumbowyg v-model="editorContent" :config="editorConfig" @tbw-change="emitContent( name )" :name="name" />
        <library @attached="getAttachedImage" />
    </div>
</template>

<script>
    import Library from '../../views/Media/Library'
    import Trumbowyg from 'vue-trumbowyg';
    import 'trumbowyg/dist/ui/trumbowyg.css';
    import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css';
    import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.min.js';
    // import 'trumbowyg/dist/plugins/emoji/ui/trumbowyg.emoji.min.css';
    // import 'trumbowyg/dist/plugins/emoji/trumbowyg.emoji.min.js';
    import 'trumbowyg/dist/plugins/pasteembed/trumbowyg.pasteembed.min.js';
    import 'trumbowyg/dist/plugins/pasteimage/trumbowyg.pasteimage.min.js';
    // import 'trumbowyg/dist/plugins/table/ui/trumbowyg.table.min.css';
    //import 'trumbowyg/dist/plugins/table/trumbowyg.table.min.js';
    import 'trumbowyg/dist/plugins/upload/trumbowyg.upload.min.js';
    import 'trumbowyg/dist/plugins/template/trumbowyg.template.min.js';
    import 'trumbowyg/dist/plugins/noembed/trumbowyg.noembed.min.js';
    import 'trumbowyg/dist/plugins/fontsize/trumbowyg.fontsize.min.js';
    import {map} from 'lodash'
    import db from '../../services/db'

    export default {
        name: 'Editor',
        data () {
            return {
                upload_ref: '',
                editorShow: false,
                editorConfig: {
                    btnsDef: {
                        mediaLibrary: {
                            fn: this.showMediaLibrary,
                            title: 'Media Library',
                            ico: 'upload'
                        }
                    },
                    btns: [
                        ['formatting'],
                        ['fontsize'],
                        ['undo', 'redo'],
                        ['strong', 'em', 'del'],
                        ['superscript', 'subscript'],
                        ['link'],
                        ['unorderedList', 'orderedList'],
                        ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                        ['insertImage','mediaLibrary','noembed'],
                        ['horizontalRule'],
                        ['foreColor', 'backColor'],
                        ['removeformat', 'viewHTML'],
                        ['fullscreen']
                    ],
                    plugins: {
                        upload: {
                            serverPath: BACKEND_URL + '/media',
                            fileFieldName: 'customFile',
                            urlPropertyName: 'url'
                        },
                        templates: null
                    }
                },
                content: ''
            }
        },
        components: {
            Trumbowyg,
            Library
        },
        props: {
            name: {
                type: String,
                default: 'content'
            },
            data: {
                type: String,
                default: ''
            },
            email: {
                type: Boolean,
                default: false
            },
            customer: {
                type: Object,
                default: () => ({
                    salutation: '',
                    title: '',
                    firstname: '',
                    lastname: ''
                })
            }
        },
        computed: {
            editorContent: {
                get () {
                    return this.data;
                },
                set ( newValue ) {
                    this.content = newValue;
                }
            }
        },
        mounted () {
            if ( this.email ) {
                this.fetchTemplates();
            } else {
                this.editorShow = true;
            }
        },
        methods: {
            fetchTemplates: function () {
                db.list('template').then((response) => {
                    // Separate premium items and non-premium items
                    let premiumItems = response.data.data.filter(o => o.premium === 1);
                    let nonPremiumItems = response.data.data.filter(o => o.premium !== 1);
                    
                    // Sort both arrays by created_at DESC
                    premiumItems.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                    nonPremiumItems.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

                    // Combine premium items on top followed by the non-premium items
                    let sortedItems = [...premiumItems, ...nonPremiumItems];

                    this.$nextTick(function() {
                        let html = sortedItems.map((o) => { 
                            let a = {};
                            if (o.premium === 1) {
                                a.name = `<span style="border-bottom: 1px solid #ebb834;">${o.title}</span>`;
                            }
                            else
                            {
                                a.name = o.title;
                            }
                            a.title = o.title;
                            a.html = this.replacePlaceholders(o.content);
                            return a;
                        });
                        this.editorConfig.btns.unshift(['template']);
                        this.editorConfig.plugins.templates = html;
                        this.editorShow = true;
                    });
                });
            },
            replacePlaceholders(content) {
                let { salutation, title, firstname, lastname } = this.customer;
                let fullname = firstname+' '+lastname;
                let titletext = (title == 2) ? 'Frau' :((title == 1) ? 'Herr' : ''); 
                // Replace placeholders with dynamic data
                content = content.replace(/{salutation}/g, salutation || '');
                content = content.replace(/{first name}}/g, firstname || '');
                content = content.replace(/{last name}}/g, lastname || '');
                content = content.replace(/{name}/g, lastname || '');
                content = content.replace(/{full name}/g, fullname || '');
                content = content.replace(/{title}/g, titletext || '');
                return content;
            },
            showMediaLibrary: function () {
                this.upload_ref = 'editor';
                this.$bvModal.show('modal-media-library');
            },
            getAttachedImage: function ( id, url ) {
                if ( this.upload_ref === 'editor' ) {
                    document.execCommand('insertImage', false, (url || ""));
                } else {
                    this.$emit('attached', id, url);
                }
            },
            emitContent (event, ref) {
                let title = '';
                if(this.editorConfig.plugins.templates)
                {
                    const selectedTemplate = this.editorConfig.plugins.templates.find(t => t.html === this.content);
                    title = selectedTemplate ? selectedTemplate.title : '';
                }
                this.$emit('content', { content: this.content, title });
               // this.$emit('content', this.content)
            }
        }
    }
</script>

<style lang="scss">
.trumbowyg-box,
.trumbowyg-editor {
    min-height: 200px;
    &:not(.trumbowyg-fullscreen) {
        max-height: 800px;
    }
}
</style>
