import db from '../../services/db'

export default {
    state: {
        list: []
    },
    getters: {
        getMessages (state, getters) {
            return state.list;
        }
    },
    actions: {
        fetchMessages ({commit}) {
            db.list('app/notification').then((response) => {
                let data = [];
                _.forEach( response.data.data, function ( v, k ) {
                    v.meta = JSON.parse( v.meta );
                    data.push( v );
                } )
                commit('setMessages', data)
            }).catch((error) => {
                console.log(error)
            });
        }
    },
    mutations: {
        setMessages (state, data) {
            state.list = data
        },
        deleteMessage (state, index) {
            state.list.splice(index, 1);
        }
    }
};
