<template>
    <multiselect v-model="selected"
                 track-by="name" label="name"
                 group-values="items" group-label="group"
                 :class="customClass" :options="options" :multiple="multiple"
                 :close-on-select="(!multiple)" :clear-on-select="false"
                 :searchable="true" :showLabels="false" />
</template>

<script>
    import 'vue-multiselect/dist/vue-multiselect.min.css'
    import {groupBy, each, map, find} from 'lodash'
    import Multiselect from "vue-multiselect";

    export default {
        name: 'CountrySelect',
        data () {
            return {
                countries: JSON.parse( dataJSON.countries )
            }
        },
        components: {
            Multiselect
        },
        props: {
            value: {},
            customClass: {
                type: String
            },
            multiple: {
                type: Boolean,
                default: false
            }
        },
        beforeMount () {},
        mounted () {},
        computed: {
            options () {
                let groups = groupBy( this.countries, function(o) {
                    return o.group;
                } );
                let a = [];
                each( groups, function ( value, key ) {
                    let label = '';
                    if ( key === '0' ) {
                        label = 'Main';
                    } else if ( key === '1' ) {
                        label = 'Europe';
                    } else if ( key === '2' ) {
                        label = 'North America';
                    } else {
                        label = 'World';
                    }
                    a.push({ group: label, items: value });
                } );
                return a;
            },
            selected: {
                get: function() {
                    return this.getValues( this.value );
                },
                set: function(value) {
                    this.$emit('input', this.findValues(value));
                }
            }
        },
        methods: {
            getValues ( value ) {
                if ( this.multiple ) {
                    let a = [],
                        options = this.countries;
                    each( value, function ( i ) {
                        let x = find( options, function ( o ) {
                            return ( o.code === i );
                        } );
                        a.push( x );
                    } );
                    return a;
                }
                else {
                    return find(this.countries, function (o) {
                        return (o.code === value);
                    });
                }
            },
            findValues ( value ) {
                if ( this.multiple ) {
                    let a = [];
                    map( value, function ( o ) {
                        a.push( o.code );
                    } );
                    return a;
                }
                else {
                    return value.code;
                }
            }
        }
    }
</script>
