import db from '../../services/db'

export default {
    state: {
        data: [],
        detail: {}
    },
    getters: {
        getEventData (state, getters) {
            return state.data;
        },
        getEventDetail : (state) => {
            return state.detail;
        },
        getEventFC: (state) => ( year ) => {
            return _.filter( state.data , item => ((item.type === 1) && (item.year === _.toInteger(year))));
        },
        getEventDE: (state) => ( id ) => {
            return _.filter( state.data , item => (item.type === 2));
        },
        getEventSM: (state) => ( id ) => {
            return _.filter( state.data , item => (item.type === 3));
        }
    },
    actions: {
        fetchEventData ({commit}, payload) {
            db.list('event').then((response) => {
                let data = _.map( response.data.data, (n) => {
                    n.user = (n.user) ? JSON.parse( n.user ) : n.user;
                    n.meta = (n.meta) ? JSON.parse( n.meta ) : n.meta;
                    n.dinner = (n.dinner) ? JSON.parse( n.dinner ) : n.dinner;
                    n.hotel = (n.hotel) ? JSON.parse( n.hotel ) : n.hotel;
                    n.event = (n.event) ? JSON.parse( n.event ) : n.event;
                    n.seminar = (n.seminar) ? JSON.parse( n.seminar ) : n.seminar;
                    // MAILS
                    n.mail_event = (n.mail_event) ? JSON.parse( n.mail_event ) : n.mail_event;
                    n.mail_general = (n.mail_general) ? JSON.parse( n.mail_general ) : n.mail_general;
                    n.mail_dinner = (n.mail_dinner) ? JSON.parse( n.mail_dinner ) : n.mail_dinner;

                    return n;
                } );
                commit('setEventData', data)
            }).catch((error) => {
                console.log(error)
            });
        },
        async fetchEventDetail ({commit}, id) {
            await db.read('event', id).then((response) => {
                let data = response.data;
                data.user = (data.user) ? JSON.parse( data.user ) : data.user;
                data.meta = (data.meta) ? JSON.parse( data.meta ) : data.meta;
                data.dinner = (data.dinner) ? JSON.parse( data.dinner ) : data.dinner;
                data.hotel = (data.hotel) ? JSON.parse( data.hotel ) : data.hotel;
                data.event = (data.event) ? JSON.parse( data.event ) : data.event;
                data.seminar = (data.seminar) ? JSON.parse( data.seminar ) : data.seminar;
                commit('setEventDetail', data)
            });
        },
    },
    mutations: {
        setEventData (state, data) {
            state.data = data
        },
        setEventDetail (state, data) {
            state.detail = data
        },
        deleteEvent (state, id) {
            let index = state.data.findIndex(i => i._id===id);
            state.data.splice(index, 1);
        }
    }
};
