import db from '../../services/db'

export default {
    state: {
        data: []
    },
    getters: {
        getBokingsData : (state) => {
            return state.data;
        },
        getBookingsFlightAward : (state) => {
            return _.filter( state.data, ( item ) => { return (item.sub_type === 3) } )
        },
        getBookingsFlightFirst : (state) => {
            return _.filter( state.data, ( item ) => { return (item.sub_type === 2) } )
        }
    },
    actions: {
        fetchBookingsData ({commit}, payload) {
            db.list('booking', JSON.stringify( payload )).then((response) => {
                let data = [];
                _.forEach( response.data.data, function ( v, k ) {
                    v.customer = JSON.parse( v.customer );
                    v.actions = JSON.parse( v.actions );
                    v.data = JSON.parse( v.data );
                    if(v.meta){
                        v.meta = JSON.parse( v.meta );
                    }
                    
                    data.push( v );
                } )
                commit('setBookingsData', data)
            }).catch((error) => {
                console.log(error)
            });
        }
    },
    mutations: {
        setBookingsData (state, data) {
            state.data = data
        }
    }
};
