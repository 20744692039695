<template>
    <b-button :variant="variant" type="submit" name="submit" :disabled="loading">
        <b-spinner small v-if="loading"/>
        <span v-else>{{label}}</span>
    </b-button>
</template>

<script>
    export default {
        props: {
            variant: { type: String, default: 'primary' },
            loading: { type: Boolean },
            label: { type: String, default: 'Submit' }
        }
    }
</script>
