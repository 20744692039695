import axios from 'axios'

let csrf_token = document.head.querySelector('meta[name="csrf-token"]') || '';

export default axios.create({
    baseURL: BACKEND_URL,
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': csrf_token.content
    }
});
