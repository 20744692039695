<template>
    <div v-if="userData">
        <Loader />
        <div class="wrapper">
            <MenuBar title="Dashboard" @toggle="sidebarMini" :user="userData">
                <template slot="right">
                    <User :user="userData" />
                </template>
            </MenuBar>
            <div id="content-page" class="content-page ml-0">
                <transition name="router-anim" :enter-active-class="`animated ${animated.enter}`" mode="out-in"
                            :leave-active-class="`animated ${animated.exit}`">
                    <router-view/>
                </transition>
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
export default {
    name: 'Layout',
    components: {},
    data () {
        return {
            userData: null,
            animated: { enter: 'fadeInUp', exit: 'fadeOut' },
            notBookmarkRouts: []
        }
    },
    created () {
        this.fetchUserData().then(() => {
            this.userData = this.getUserData
        })
    },
    mounted () {
        this.updateRadio()
    },
    computed: {
        ...mapGetters(['getUserData'])
    },
    methods: {
        ...mapActions(['fetchUserData']),
        updateRadio () {
            this.mini = this.$store.getters['Setting/miniSidebarState']
        },
        sidebarMini () {
            core.triggerSet()
            this.$store.dispatch('Setting/miniSidebarAction')
            this.updateRadio()
        }
    }
}
</script>
