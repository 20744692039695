import db from '../../services/db';
import moment from 'moment';

export default {
    state: {
        data: {
            user: {}
        },
        list: []
    },
    getters: {
        getSupportDetail : (state) => {
            return state.data;
        },
        getSupportDiamondContact : (state) => {
            return _.filter( state.list, ( item ) => { return (item.type === 1) } )
        },
        getSupportPersonalAdvisory : (state) => {
            return _.filter( state.list, ( item ) => { return (item.type === 3) } )
        },
        getSupportContactForm : (state) => {
            return _.filter( state.list, ( item ) => { return (item.type === 2) } )
        }
    },
    actions: {
        fetchSupportList ({commit}, payload) {
           // db.list('support', JSON.stringify({type: 1 }), null , page).then((response) => {
            let fields = "k_type,assigned_at,assigned_id,created_at,draft_by,draft_by_name,editing,last_update_admin,last_update_customer,priority,sent_by,sent_by_name,updated_at,k_status,k_user,k_title";
            db.list('support', JSON.stringify( payload ), fields, null).then((response) => {
                let data = [];
                _.forEach( response.data.data, function ( v, k ) {

                    if(v.last_update_admin && v.status == 2)
                    {
                        const lastUpdateCustomer = moment(v.last_update_customer);
                        const lastUpdateAdmin = moment(v.last_update_admin);

                        const responseTimeHours = lastUpdateAdmin.diff(lastUpdateCustomer, 'hours', true);

                        // Set response time based on duration
                            v.responsetime = responseTimeHours.toFixed(2);
                    }
                    else{
                        v.responsetime = '';
                    }
                    if (typeof v.user === 'string') {
                        try {
                            v.user = JSON.parse(v.user);
                        } catch (e) {
                            console.error('Error parsing user data:', e);
                        }
                    }
                    data.push(v);
                } )
                commit('setSupportList', data)
            }).catch((error) => {
                console.log(error)
            });
        },
        async fetchSupportDetail ({commit}, id) {
            await db.read('support', id).then((response) => {
                let data = response.data;
                if(data.user){
                data.user = JSON.parse( data.user );
                }
                commit('setSupportDetail', data)
            }).catch((error) => {
                console.log(error)
            });
        }
    },
    mutations: {
        setSupportList (state, data) {
            state.list = data
        },
        setSupportDetail (state, data) {
            state.data = data
        },
        deleteSupportEntry (state, index) {
            state.list.splice(index, 1);
        }
    }
};
