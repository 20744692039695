import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../layouts/Layout'
import AuthLayout from '../layouts/AuthLayout'
import SignIn from '../views/Auth/SignIn'
import Dashboard from '../views/Dashboards/Dashboard.vue'

Vue.use(VueRouter);

const dashboardChildRoutes = (prop, mode = false) => [
    {
        path: 'home',
        name: prop + '.home',
        meta: { dark: mode, auth: true, name: 'Dashboard' },
        component: Dashboard
    }
];
const authChildRoutes = (prop, mode = false) => [
    {
        path: 'sign-in',
        name: prop + '.sign-in',
        meta: { dark: mode, auth: false },
        component: SignIn
    }
];
const userChildRoute = (prop, mode = false) => [
    {
        path: '',
        name: prop + '.list',
        meta: { dark: mode, auth: true, name: 'All Users' },
        component: () =>
            import( /* webpackChunkName: "userList" */ '../views/User/List' )
    },
    {
        path: 'add',
        name: prop + '.add',
        meta: { dark: mode, auth: true, name: 'Add a User' },
        component: () =>
            import( /* webpackChunkName: "userEdit" */ '../views/User/Edit' )
    },
    {
        path: ':id',
        name: prop + '.edit',
        meta: { dark: mode, auth: true, name: 'Edit a User' },
        component: () =>
            import( /* webpackChunkName: "userEdit" */ '../views/User/Edit' )
    }
];
const roleChildRoute = (prop, mode = false) => [
    {
        path: '',
        name: prop + '.list',
        meta: { dark: mode, auth: true, name: 'All Roles' },
        component: () =>
            import( /* webpackChunkName: "roleList" */ '../views/Role/List' )
    },
    {
        path: 'add',
        name: prop + '.add',
        meta: { dark: mode, auth: true, name: 'Add a Role' },
        component: () =>
            import( /* webpackChunkName: "roleEdit" */ '../views/Role/Edit' )
    },
    {
        path: ':id',
        name: prop + '.edit',
        meta: { dark: mode, auth: true, name: 'Edit a Role' },
        component: () =>
            import( /* webpackChunkName: "roleEdit" */ '../views/Role/Edit' )
    }
];
const customerChildRoute = (prop, mode = false) => [
    {
        path: '',
        name: prop + '.list',
        meta: { dark: mode, auth: true },
        component: () =>
            import( /* webpackChunkName: "customerList" */ '../views/Customer/List' )
    },
    {
        path: 'register-users',
        name: prop + '.list-ru',
        meta: { dark: mode, auth: true },
        component: () =>
            import( /* webpackChunkName: "customerListRU" */ '../views/Customer/ListRU' )
    },
    {
        path: 'royal-circle',
        name: prop + '.list-rc',
        meta: { dark: mode, auth: true },
        component: () =>
            import( /* webpackChunkName: "customerListRC" */ '../views/Customer/ListRC' )
    },
    {
        path: 'royal-circle/:id',
        name: prop + '.edit-rc',
        meta: { dark: mode, auth: true },
        component: () =>
            import( /* webpackChunkName: "CustomerEditRC" */ '../views/Customer/EditRC' )
    },
    {
        path: 'blocked-users',
        name: prop + '.list-bc',
        meta: { dark: mode, auth: true },
        component: () =>
        import( /* webpackChunkName: "customerListBC" */ '../views/Customer/ListBC' )
    },
    {
        path: 'add',
        name: prop + '.add',
        meta: { dark: mode, auth: true },
        component: () =>
            import( /* webpackChunkName: "customerCreate" */ '../views/Customer/Create' )
    },
    {
        path: ':id',
        name: prop + '.edit',
        meta: { dark: mode, auth: true },
        component: () =>
            import( /* webpackChunkName: "customerEdit" */ '../views/Customer/Edit' )
    }
];
const extensionsChildRoute = (prop, mode = false) => [
    {
        path: '',
        name: prop + '.list',
        meta: { dark: mode, auth: true, name: 'Extensions & Upgrades' },
        component: () =>
            import( /* webpackChunkName: "extensionsEdit" */ '../views/Extensions/List' )
    }
];
const orderChildRoute = (prop, mode = false) => [
    {
        path: '',
        name: prop + '.list',
        meta: { dark: mode, auth: true, name: 'Produktbestellungen Liste' },
        component: () =>
            import( /* webpackChunkName: "orderList" */ '../views/Order/List' )
    },
    {
        path: 'view/:id',
        name: prop + '.view',
        meta: { dark: mode, auth: true, name: 'View an Order' },
        component: () =>
            import( /* webpackChunkName: "orderView" */ '../views/Order/View' )
    },
    {
        path: 'edit-address/:id',
        name: prop + '.editaddress',
        meta: { dark: mode, auth: true, name: 'Edit Address' },
        component: () =>
            import( /* webpackChunkName: "editAddress" */ '../views/Order/EditAddress' )
    },
    {
        path: 'edit-price/:id',
        name: prop + '.editprice',
        meta: { dark: mode, auth: true, name: 'Edit Price' },
        component: () =>
            import( /* webpackChunkName: "editPrice" */ '../views/Order/EditPrice' )
    },
    {
        path: 'send-message/:id',
        name: prop + '.sendmessage',
        meta: { dark: mode, auth: true, name: 'Message Detail' },
        component: () =>
            import( /* webpackChunkName: "sendMessage" */ '../views/Order/Message' )
    },
    {
        path: ':id',
        name: prop + '.edit',
        meta: { dark: mode, auth: true, name: 'Edit an Order' },
        component: () =>
            import( /* webpackChunkName: "orderEdit" */ '../views/Order/Edit' )
    }
];
const billingChildRoute = (prop, mode = false) => [
    {
        path: '',
        name: prop + '.list',
        meta: { dark: mode, auth: true, name: 'Billing Liste' },
        component: () =>
            import( /* webpackChunkName: "billingList" */ '../views/Billing/List' )
    },
    {
        path: 'add',
        name: prop + '.add',
        meta: { dark: mode, auth: true, name: 'Add a Bill' },
        component: () =>
            import( /* webpackChunkName: "billingEdit" */ '../views/Billing/Edit' )
    },
    {
        path: ':id',
        name: prop + '.edit',
        meta: { dark: mode, auth: true, name: 'Edit a Bill' },
        component: () =>
            import( /* webpackChunkName: "billingEdit" */ '../views/Billing/Edit' )
    },
    {
        path: 'view/:id',
        name: prop + '.view',
        meta: { dark: mode, auth: true, name: 'Message Detail' },
        component: () =>
            import( /* webpackChunkName: "enquiriesView" */ '../views/Billing/ViewMessage' )
    },
];

const seminarChildRoute = (prop, mode = false) => [
    {
        path: '',
        name: prop + '.list',
        meta: { dark: mode, auth: true, name: 'Seminar Liste' },
        component: () =>
            import( /* webpackChunkName: "seminarList" */ '../views/Seminar/List' )
    },
    {
        path: 'add',
        name: prop + '.add',
        meta: { dark: mode, auth: true, name: 'Add a Seminar' },
        component: () =>
            import( /* webpackChunkName: "seminarEdit" */ '../views/Seminar/Edit' )
    },
    {
        path: ':id',
        name: prop + '.edit',
        meta: { dark: mode, auth: true, name: 'Edit a Seminar' },
        component: () =>
            import( /* webpackChunkName: "seminarEdit" */ '../views/Seminar/Edit' )
    },
];

const invoicesChildRoute = (prop, mode = false) => [
    {
        path: '',
        name: prop + '.list',
        meta: { dark: mode, auth: true, name: 'Invoice Liste' },
        component: () =>
            import( /* webpackChunkName: "invoicesList" */ '../views/Invoices/List' )
    },
    {
        path: 'add',
        name: prop + '.add',
        meta: { dark: mode, auth: true, name: 'Add a Invoice' },
        component: () =>
            import( /* webpackChunkName: "invoicesEdit" */ '../views/Invoices/Edit' )
    },
    {
        path: 'international',
        name: prop + '.international',
        meta: { dark: mode, auth: true, name: 'International List' },
        component: () =>
            import( /* webpackChunkName: "internationalList" */ '../views/Invoices/InternationalList' ) // Create this component
    },
    {
        path: ':id',
        name: prop + '.edit',
        meta: { dark: mode, auth: true, name: 'Edit a Invoice' },
        component: () =>
            import( /* webpackChunkName: "invoicesEdit" */ '../views/Invoices/Edit' )
    },
    {
        path: 'view/:id',
        name: prop + '.view',
        meta: { dark: mode, auth: true, name: 'Message Detail' },
        component: () =>
            import( /* webpackChunkName: "invoicesView" */ '../views/Invoices/ViewMessage' )
    },
    
];

const productChildRoute = (prop, mode = false) => [
    {
        path: '',
        name: prop + '.list',
        meta: { dark: mode, auth: true, name: 'All Products' },
        component: () =>
            import( /* webpackChunkName: "productList" */ '../views/Product/List' )
    },
    {
        path: 'add',
        name: prop + '.add',
        meta: { dark: mode, auth: true, name: 'Add a Product' },
        component: () =>
            import( /* webpackChunkName: "productEdit" */ '../views/Product/Edit' )
    },
    {
        path: ':id',
        name: prop + '.edit',
        meta: { dark: mode, auth: true, name: 'Edit a Product' },
        component: () =>
            import( /* webpackChunkName: "productEdit" */ '../views/Product/Edit' )
    }
];
const subscriberChildRoute = (prop, mode = false) => [
    {
        path: '',
        name: prop + '.list',
        meta: { dark: mode, auth: true, name: 'Newsletter-Subscribers' },
        component: () =>
            import( /* webpackChunkName: "subscriberList" */ '../views/Subscriber/List' )
    },
    {
        path: 'gewennspiel',
        name: prop + '.list-gw',
        meta: { dark: mode, auth: true, name: 'Gewinnspiel Teilnehmer' },
        component: () =>
            import( /* webpackChunkName: "subscriberListGW" */ '../views/Subscriber/ListGW' )
    },
    {
        path: 'add',
        name: prop + '.add',
        meta: { dark: mode, auth: true, name: 'Add a Subscriber' },
        component: () =>
            import( /* webpackChunkName: "subscriberEdit" */ '../views/Subscriber/Edit' )
    },
    {
        path: ':id',
        name: prop + '.edit',
        meta: { dark: mode, auth: true, name: 'Edit a Subscriber' },
        component: () =>
            import( /* webpackChunkName: "subscriberEdit" */ '../views/Subscriber/Edit' )
    }
];
const voucherChildRoute = (prop, mode = false) => [
    {
        path: '',
        name: prop + '.list',
        meta: { dark: mode, auth: true, name: 'All Vouchers' },
        component: () =>
            import( /* webpackChunkName: "voucherList" */ '../views/Voucher/List' )
    },
    {
        path: 'add',
        name: prop + '.add',
        meta: { dark: mode, auth: true, name: 'Add a Voucher' },
        component: () =>
            import( /* webpackChunkName: "voucherEdit" */ '../views/Voucher/Edit' )
    },
    {
        path: ':id',
        name: prop + '.edit',
        meta: { dark: mode, auth: true, name: 'Edit a Voucher' },
        component: () =>
            import( /* webpackChunkName: "voucherEdit" */ '../views/Voucher/Edit' )
    }
];
const mediaChildRoute = (prop, mode = false) => [
    {
        path: '',
        name: prop + '.list',
        meta: { dark: mode, auth: true, name: 'All Media Files' },
        component: () =>
            import( /* webpackChunkName: "mediaList" */ '../views/Media/List' )
    },
    {
        path: 'add',
        name: prop + '.add',
        meta: { dark: mode, auth: true, name: 'Upload Files' },
        component: () =>
            import( /* webpackChunkName: "mediaAdd" */ '../views/Media/Add' )
    }
];
const supportChildRoute = (prop, mode = false) => [
    {
        path: 'contact-form',
        name: prop + '.list-cf',
        meta: { dark: mode, auth: true, name: 'Contact Forms' },
        component: () =>
            import( /* webpackChunkName: "supportListCF" */ '../views/Support/ListCF' )
    },
    {
        path: 'diamond-contacts',
        name: prop + '.list-dc',
        meta: { dark: mode, auth: true, name: 'Diamond Contacts' },
        component: () =>
            import( /* webpackChunkName: "supportListDC" */ '../views/Support/ListDC' ),
    },
    {
        path: 'personal-advisory',
        name: prop + '.list-pa',
        meta: { dark: mode, auth: true, name: 'Persönliche Beratung' },
        component: () =>
            import( /* webpackChunkName: "supportListPA" */ '../views/Support/ListPA' ),
    },
    {
        path: ':id',
        name: prop + '.view',
        meta: { dark: mode, auth: true, name: 'Message Detail' },
        component: () =>
            import( /* webpackChunkName: "supportView" */ '../views/Support/View' )
    }
];
const messageChildRoute = (prop, mode = false) => [
    {
        path: '',
        name: prop + '.add',
        meta: { dark: mode, auth: true, name: 'Send a Message' },
        component: () =>
            import( /* webpackChunkName: "messageAdd" */ '../views/Message/Add' )
    }
];
const templateChildRoute = (prop, mode = false) => [
    {
        path: '',
        name: prop + '.list',
        meta: { dark: mode, auth: true, name: 'All Templates' },
        component: () =>
            import( /* webpackChunkName: "templateList" */ '../views/Template/List' )
    },
    {
        path: 'add',
        name: prop + '.add',
        meta: { dark: mode, auth: true, name: 'Create a Template' },
        component: () =>
            import( /* webpackChunkName: "templateEdit" */ '../views/Template/Edit' )
    },
    {
        path: ':id',
        name: prop + '.edit',
        meta: { dark: mode, auth: true, name: 'Update a Template' },
        component: () =>
            import( /* webpackChunkName: "templateEdit" */ '../views/Template/Edit' )
    }
];
const hotelChildRoute = (prop, mode = false) => [
    {
        path: '',
        name: prop + '.list',
        meta: { dark: mode, auth: true, name: 'All Hotels' },
        component: () =>
            import( /* webpackChunkName: "hotelList" */ '../views/Hotel/List' )
    },
    {
        path: 'add',
        name: prop + '.add',
        meta: { dark: mode, auth: true, name: 'Add a Hotel' },
        component: () =>
            import( /* webpackChunkName: "hotelEdit" */ '../views/Hotel/Edit' )
    },
    {
        path: ':id',
        name: prop + '.edit',
        meta: { dark: mode, auth: true, name: 'Edit a Hotel' },
        component: () =>
            import( /* webpackChunkName: "hotelEdit" */ '../views/Hotel/Edit' )
    }
];
const lotteryChildRoute = (prop, mode = false) => [
    {
        path: '',
        name: prop + '.list',
        meta: { dark: mode, auth: true, name: 'Gewinnspiel Inhalte' },
        component: () =>
            import( /* webpackChunkName: "lotteryList" */ '../views/Lottery/List' )
    },
    {
        path: 'add',
        name: prop + '.add',
        meta: { dark: mode, auth: true, name: 'Add Gewinnspiel Inhalte' },
        component: () =>
            import( /* webpackChunkName: "lotteryEdit" */ '../views/Lottery/Edit' )
    },
    {
        path: ':id',
        name: prop + '.edit',
        meta: { dark: mode, auth: true, name: 'Edit Gewinnspiel Inhalte' },
        component: () =>
            import( /* webpackChunkName: "lotteryEdit" */ '../views/Lottery/Edit' )
    }
];
const accountChildRoute = (prop, mode = false) => [
    {
        path: '',
        name: prop + '.list',
        meta: { dark: mode, auth: true, name: 'All Entries' },
        component: () =>
            import( /* webpackChunkName: "accountList" */ '../views/Account/List' )
    },
    {
        path: 'add',
        name: prop + '.add',
        meta: { dark: mode, auth: true, name: 'Add an Entry' },
        component: () =>
            import( /* webpackChunkName: "accountEdit" */ '../views/Account/Edit' )
    },
    {
        path: ':id',
        name: prop + '.edit',
        meta: { dark: mode, auth: true, name: 'Edit an Entry' },
        component: () =>
            import( /* webpackChunkName: "accountEdit" */ '../views/Account/Edit' )
    }
];
const publicationChildRoute = (prop, mode = false) => [
    {
        path: '',
        name: prop + '.list',
        meta: { dark: mode, auth: true, name: 'All Publications' },
        component: () =>
            import( /* webpackChunkName: "publicationList" */ '../views/Publication/List' )
    },
    {
        path: 'add',
        name: prop + '.add',
        meta: { dark: mode, auth: true, name: 'Add a Publication' },
        component: () =>
            import( /* webpackChunkName: "publicationEdit" */ '../views/Publication/Edit' )
    },
    {
        path: ':id',
        name: prop + '.edit',
        meta: { dark: mode, auth: true, name: 'Edit a Publication' },
        component: () =>
            import( /* webpackChunkName: "publicationEdit" */ '../views/Publication/Edit' )
    }
];
const referralChildRoute = (prop, mode = false) => [
    {
        path: '',
        name: prop + '.list',
        meta: { dark: mode, auth: true, name: 'All Referrals' },
        component: () =>
            import( /* webpackChunkName: "referralList" */ '../views/Referral/List' )
    }
];
const bookingChildRoute = (prop, mode = false) => [
    {
        path: 'business-first-class',
        name: prop + '.list-bf',
        meta: { dark: mode, auth: true, name: 'Business & First Class Requests' },
        component: () =>
            import( /* webpackChunkName: "bookingListBF" */ '../views/Booking/ListBF' )
    },
    {
        path: 'award-requests',
        name: prop + '.list-aw',
        meta: { dark: mode, auth: true, name: 'Award Booking Requests' },
        component: () =>
            import( /* webpackChunkName: "bookingListAW" */ '../views/Booking/ListAW' )
    },
    {
        path: 'manual-hotel-bookings',
        name: prop + '.list-ht',
        meta: { dark: mode, auth: true, name: 'Manual Hotel Booking' },
        component: () =>
            import( /* webpackChunkName: "HotelListManual" */ '../views/Enquiries/Hotel/List' )
    },
    {
        path: 'manual-hotel-bookings-vip',
        name: prop + '.list-vip',
        meta: { dark: mode, auth: true, name: 'Manual Hotel Booking - VIP' },
        component: () =>
            import( /* webpackChunkName: "HotelListVip" */ '../views/Enquiries/Hotel/ListVip' )
    },
    {
        path: 'add-hotel',
        name: prop + '.add-hotel',
        meta: { dark: mode, auth: true, name: 'Add a Hotel Booking' },
        component: () =>
            import( /* webpackChunkName: "enquiriesHotelEdit" */ '../views/Enquiries/Hotel/Edit' )
    },
    {
        path: 'edit-hotel/:id',
        name: prop + '.edit-hotel',
        meta: { dark: mode, auth: true, name: 'Edit a Hotel Booking' },
        component: () =>
            import( /* webpackChunkName: "enquiriesHotelEdit" */ '../views/Enquiries/Hotel/Edit' )
    },
    
    {
        path: ':id',
        name: prop + '.view',
        meta: { dark: mode, auth: true, name: 'View a Request' },
        component: () =>
            import( /* webpackChunkName: "bookingView" */ '../views/Booking/View' )
    },
    {
        path: 'message/:id',
        name: prop + '.message',
        meta: { dark: mode, auth: true, name: 'Message Detail' },
        component: () =>
            import( /* webpackChunkName: "BookingMessage" */ '../views/Booking/BookingView' )
    }
];
const pagesChildRoute = (prop, mode = false) => [
    {
        path: '',
        name: prop + '.list',
        meta: { dark: mode, auth: true, name: 'All Pages' },
        component: () =>
            import( /* webpackChunkName: "pageList" */ '../views/Pages/List' )
    },
    {
        path: 'add',
        name: prop + '.add',
        meta: { dark: mode, auth: true, name: 'Add a Page' },
        component: () =>
            import( /* webpackChunkName: "pageEdit" */ '../views/Pages/Edit' )
    },
    {
        path: ':id',
        name: prop + '.edit',
        meta: { dark: mode, auth: true, name: 'Edit a Page' },
        component: () =>
            import( /* webpackChunkName: "pageEdit" */ '../views/Pages/Edit' )
    }
];
const newsletterChildRoute = (prop, mode = false) => [
    {
        path: 'generate',
        name: prop + '.generate',
        meta: { dark: mode, auth: true, name: 'Generate Newsletter' },
        component: () =>
            import( /* webpackChunkName: "newsletterGenerate" */ '../views/Newsletter/Generate' )
    },
    {
        path: 'edit/:id',
        name: prop + '.edit',
        meta: { dark: mode, auth: true, name: 'Preview & Edit' },
        component: () =>
            import( /* webpackChunkName: "newsletterEdit" */ '../views/Newsletter/Edit' )
    },
    {
        path: 'draft',
        name: prop + '.draft',
        meta: { dark: mode, auth: true, name: 'Draft Items' },
        component: () =>
            import( /* webpackChunkName: "newsletterDraft" */ '../views/Newsletter/Draft' )
    },
    {
        path: 'sent',
        name: prop + '.sent',
        meta: { dark: mode, auth: true, name: 'Sent Items' },
        component: () =>
            import( /* webpackChunkName: "newsletterSent" */ '../views/Newsletter/Sent' )
    },
    {
        path: 'layout',
        name: prop + '.layout',
        meta: { dark: mode, auth: true, name: 'Newsletter Layout' },
        component: () =>
            import( /* webpackChunkName: "newsletterList" */ '../views/Newsletter/Layout' )
    }
];
const receiverChildRoute = (prop, mode = false) => [
    {
        path: '',
        name: prop + '.list',
        meta: { dark: mode, auth: true, name: 'Receivers Lists' },
        component: () =>
            import( /* webpackChunkName: "receiverList" */ '../views/Receiver/List' )
    },
    
    {
        path: 'add',
        name: prop + '.add',
        meta: { dark: mode, auth: true, name: 'Create a List' },
        component: () =>
            import( /* webpackChunkName: "receiverAdd" */ '../views/Receiver/Edit' ),
    },
    {
        path: ':id',
        name: prop + '.edit',
        meta: { dark: mode, auth: true, name: 'Edit a List' },
        component: () =>
            import( /* webpackChunkName: "receiverEdit" */ '../views/Receiver/Edit' )
    }
];
const enquiriesChildRoute = (prop, mode = false) => [
    {
        path: 'edit-knowledge/:id',
        name: prop + '.edit-knowledge',
        meta: { dark: mode, auth: true, name: 'Message Detail' },
        component: () =>
            import( /* webpackChunkName: "EditKnowledge" */ '../views/Enquiries/EditKN' )
    },
    {
        path: 'bonus/:id',
        name: prop + '.view-gha',
        meta: { dark: mode, auth: true, name: 'Message Detail' },
        component: () =>
        import( /* webpackChunkName: "enquiriesViewGHA" */ '../views/Enquiries/Bonus/EnquiriesView' )
    },
    {
        path: 'status-levels',
        name: prop + '.list-sl',
        meta: { dark: mode, auth: true, name: 'Status Levels' },
        component: () =>
            import( /* webpackChunkName: "enquiriesListSL" */ '../views/Enquiries/ListSL' ),
        children: [
            {
                path: 'overview',
                name: prop + '.list-sl',
                meta: { dark: mode, auth: true, name: 'Status Levels' },
                component: () =>
                    import( /* webpackChunkName: "enquiriesListSLOverview" */ '../views/Enquiries/Status/Overview' ),
            },
            {
                path: 'gha-titanium',
                name: prop + '.list-gha-t',
                meta: { dark: mode, auth: true, sub: 3, name: 'GHA Discovery - Titanium' },
                component: () =>
                    import( /* webpackChunkName: "enquiriesListSLGha" */ '../views/Enquiries/Status/GHA' ),
            },
            {
                path: 'gha-platinum',
                name: prop + '.list-gha-p',
                meta: { dark: mode, auth: true, sub: 4, name: 'GHA Discovery - Platinum' },
                component: () =>
                    import( /* webpackChunkName: "enquiriesListSLGha" */ '../views/Enquiries/Status/GHA' ),
            },
            {
                path: 'manager-magazine',
                name: prop + '.list-manager',
                meta: { dark: mode, auth: true, name: 'Manager Magazine' },
                component: () =>
                    import( /* webpackChunkName: "enquiriesListSLManager" */ '../views/Enquiries/Status/Manager' ),
            },
            {
                path: 'hotmiles-platinum',
                name: prop + '.list-hotmiles-p',
                meta: { dark: mode, auth: true,  sub: 5, name: 'Hot Miles - Platinum' },
                component: () =>
                    import( /* webpackChunkName: "enquiriesListSLHotMiles" */ '../views/Enquiries/Status/Hot' ),
            },
            {
                path: 'hotmiles-gold',
                name: prop + '.list-hotmiles-g',
                meta: { dark: mode, auth: true,  sub: 6, name: 'Hot Miles - Gold' },
                component: () =>
                    import( /* webpackChunkName: "enquiriesListSLHotMiles" */ '../views/Enquiries/Status/Hot' ),
            },
            {
                path: 'hon-circle',
                name: prop + '.list-honcircle',
                meta: { dark: mode, auth: true, name: 'HON Circle Liste' },
                component: () =>
                    import( /* webpackChunkName: "enquiriesListHONCircle" */ '../views/Enquiries/Status/HonCircle' ),
            },
            {
                path: 'aeroplan',
                name: prop + '.list-aeroplan',
                meta: { dark: mode, auth: true, name: 'Aeroplan Liste' },
                component: () =>
                    import( /* webpackChunkName: "enquiriesListAeroplan" */ '../views/Enquiries/Status/Aeroplan' ),
            }
        ]
    },
    {
        path: 'ihr-bonus',
        name: prop + '.list-ib',
        meta: { dark: mode, auth: true, name: 'Ihr Bonus' },
        component: () =>
            import( /* webpackChunkName: "enquiriesListIB" */ '../views/Enquiries/ListIB' ),
        children: [
            {
                path: 'overview',
                name: prop + '.list-ib',
                meta: { dark: mode, auth: true, name: 'Ihr Bonus' },
                component: () =>
                    import( /* webpackChunkName: "enquiriesListIBOverview" */ '../views/Enquiries/Bonus/Overview' ),
            },
            {
                path: 'gha',
                name: prop + '.list-gha',
                meta: { dark: mode, auth: true, name: 'GHA-Guthaben' },
                component: () =>
                    import( /* webpackChunkName: "enquiriesListIBGHA" */ '../views/Enquiries/Bonus/GHA' )
            },
            
            {
                path: 'gha-200',
                name: prop + '.list-gha-200',
                meta: { dark: mode, auth: true, name: 'GHA 200' },
                component: () =>
                    import( /* webpackChunkName: "enquiriesListIBGHA200" */ '../views/Enquiries/Bonus/GHA200' ),
            },
            {
                path: 'gha-500',
                name: prop + '.list-gha-500',
                meta: { dark: mode, auth: true, name: 'GHA 500' },
                component: () =>
                    import( /* webpackChunkName: "enquiriesListIBGHA500" */ '../views/Enquiries/Bonus/GHA500' ),
            },
            {
                path: 'jumeirah',
                name: prop + '.list-jumeirah',
                meta: { dark: mode, auth: true, name: 'Jumeirah Gold Status' },
                component: () =>
                    import( /* webpackChunkName: "enquiriesListIBJumeirah" */ '../views/Enquiries/Bonus/Jumeirah' ),
            }
        ]
    },
    {
        path: 'expert-flyers',
        name: prop + '.list-ef',
        meta: { dark: mode, auth: true, name: 'Expert Flyer\'s' },
        component: () =>
            import( /* webpackChunkName: "enquiriesListEF" */ '../views/Enquiries/ListEF' ),
    },
    {
        path: 'knowledge',
        name: prop + '.list-kn',
        meta: { dark: mode, auth: true, name: 'Knowledge' },
        component: () =>
            import( /* webpackChunkName: "enquiriesListKN" */ '../views/Enquiries/ListKN' ),
    },
    {
        path: 'manual-hotel-bookings',
        name: prop + '.list-ht',
        meta: { dark: mode, auth: true, name: 'Manual Hotel Booking' },
        component: () =>
            import( /* webpackChunkName: "enquiriesHotelList" */ '../views/Enquiries/Hotel/List' )
    },
    
    {
        path: 'add-hotel',
        name: prop + '.add-hotel',
        meta: { dark: mode, auth: true, name: 'Add a Hotel Booking' },
        component: () =>
            import( /* webpackChunkName: "enquiriesHotelEdit" */ '../views/Enquiries/Hotel/Edit' )
    },
    {
        path: ':id',
        name: prop + '.view',
        meta: { dark: mode, auth: true, name: 'Message Detail' },
        component: () =>
            import( /* webpackChunkName: "enquiriesView" */ '../views/Enquiries/EnquiriesView' )
    },
    
    {
        path: 'edit-hotel/:id',
        name: prop + '.edit-hotel',
        meta: { dark: mode, auth: true, name: 'Edit a Hotel Booking' },
        component: () =>
            import( /* webpackChunkName: "enquiriesHotelEdit" */ '../views/Enquiries/Hotel/Edit' )
    }
];

const founderClubChildRoute = (prop, mode = false) => [
    {
        path: ':year',
        name: prop + '.list',
        meta: {dark: mode, auth: true, name: 'Founder\'s Club'},
        component: () =>
            import( /* webpackChunkName: "founderClubLayout" */ '../views/FounderClub/Layout' ),
        children: [
            {
                path: '',
                name: prop + '.list-overview',
                meta: { dark: mode, auth: true, name: 'Founder\'s Club' },
                component: () =>
                    import( /* webpackChunkName: "founderClubListOverview" */ '../views/FounderClub/List/Overview' ),
            },
            {
                path: 'hotel',
                name: prop + '.list-hotel',
                meta: { dark: mode, auth: true, name: 'Founder\'s Club' },
                component: () =>
                    import( /* webpackChunkName: "founderClubListHotel" */ '../views/FounderClub/List/Hotel' ),
            },
            {
                path: 'dinner',
                name: prop + '.list-dinner',
                meta: { dark: mode, auth: true, name: 'Founder\'s Club' },
                component: () =>
                    import( /* webpackChunkName: "founderClubListDinner" */ '../views/FounderClub/List/Dinner' ),
            },
            {
                path: 'seminar',
                name: prop + '.list-seminar',
                meta: { dark: mode, auth: true, name: 'Founder\'s Club' },
                component: () =>
                    import( /* webpackChunkName: "founderClubListSeminar" */ '../views/FounderClub/List/Seminar' ),
            }
        ]
    }
];

const founderClubMailChildRoute = (prop, mode = false) => [
    {
        path: ':id/general',
        name: prop + '.general-message',
        meta: { dark: mode, auth: true, name: 'Founder\'s Club - Overview Message', category : 'general' },
        component: () =>
            import( /* webpackChunkName: "founderClubListOverviewMessage" */ '../views/FounderClub/Message' ),
    },
    {
        path: ':id/dinner',
        name: prop + '.dinner-message',
        meta: { dark: mode, auth: true, name: 'Founder\'s Club - Dinner Message', category : 'dinner' },
        component: () =>
            import( /* webpackChunkName: "founderClubListDinnerMessage" */ '../views/FounderClub/Message' ),
    },
    {
        path: ':id/event',
        name: prop + '.event-message',
        meta: { dark: mode, auth: true, name: 'Founder\'s Club - Event Message', category : 'event' },
        component: () =>
            import( /* webpackChunkName: "founderClubListEventMessage" */ '../views/FounderClub/Message' ),
    },
    {
        path: ':id/seminar',
        name: prop + '.seminar-message',
        meta: { dark: mode, auth: true, name: 'Founder\'s Club - seminar Message', category : 'seminar' },
        component: () =>
            import( /* webpackChunkName: "founderClubListSeminarMessage" */ '../views/FounderClub/Message' ),
    }
];


const eventChildRoute = (prop, mode = false) => [
    {
        path: '',
        name: prop + '.list',
        meta: {dark: mode, auth: true, name: ''},
        component: () =>
            import( /* webpackChunkName: "eventLayout" */ '../views/Events/Layout' ),
        children: [
            {
                path: 'diamant-event',
                name: prop + '.list-diamond',
                meta: { dark: mode, auth: true, name: 'Diamond Event' },
                component: () =>
                    import( /* webpackChunkName: "diamondEventList" */ '../views/Events/ListDE' ),
            },
            {
                path: 'seminar',
                name: prop + '.list-seminar',
                meta: { dark: mode, auth: true, name: 'Seminar Liste' },
                component: () =>
                
                    import( /* webpackChunkName: "eventSeminarList" */ '../views/Events/Seminar/List' ),
            }
        ]
    },
    {
        path: 'seminar/:id',
        name: prop + '.edit-seminar',
        meta: { dark: mode, auth: true, name: 'Seminar Register Details' },
        component: () =>
            import( /* webpackChunkName: "eventSeminarEdit" */ '../views/Events/Seminar/Edit' )
    }
];
const promosChildRoute = (prop, mode = false) => [
    {
        path: '',
        name: prop + '.list',
        meta: { dark: mode, auth: true, name: 'App Promos' },
        component: () =>
            import( /* webpackChunkName: "appPromosList" */ '../views/Promos/List' )
    },
    {
        path: 'settings',
        name: prop + '.settings',
        meta: { dark: mode, auth: true, name: 'Promos Settings' },
        component: () =>
            import( /* webpackChunkName: "appPromosSettings" */ '../views/Promos/Settings' )
    },
    {
        path: 'add',
        name: prop + '.add',
        meta: { dark: mode, auth: true, name: 'Add a Promo' },
        component: () =>
            import( /* webpackChunkName: "appPromosEdit" */ '../views/Promos/Edit' )
    },
    {
        path: ':id',
        name: prop + '.edit',
        meta: { dark: mode, auth: true, name: 'Edit a Promo' },
        component: () =>
            import( /* webpackChunkName: "appPromosEdit" */ '../views/Promos/Edit' )
    }
];
const notificationsChildRoute = (prop, mode = false) => [
    {
        path: '',
        name: prop + '.list',
        meta: { dark: mode, auth: true, name: 'Push Notifications' },
        component: () =>
            import( /* webpackChunkName: "appNotificationsList" */ '../views/Notifications/List' )
    },
    {
        path: 'add',
        name: prop + '.add',
        meta: { dark: mode, auth: true, name: 'Create a Notification' },
        component: () =>
            import( /* webpackChunkName: "appNotificationsEdit" */ '../views/Notifications/Edit' )
    },
    {
        path: ':id',
        name: prop + '.edit',
        meta: { dark: mode, auth: true, name: 'Edit a Notification' },
        component: () =>
            import( /* webpackChunkName: "appNotificationsEdit" */ '../views/Notifications/Edit' )
    }
];
const pressChildRoute = (prop, mode = false) => [
    {
        path: '',
        name: prop + '.list',
        meta: { dark: mode, auth: true, name: 'Press Releases' },
        component: () =>
            import( /* webpackChunkName: "pressList" */ '../views/Press/List' )
    },
    {
        path: 'add',
        name: prop + '.add',
        meta: { dark: mode, auth: true, name: 'Create a Press' },
        component: () =>
            import( /* webpackChunkName: "pressEdit" */ '../views/Press/Edit' )
    },
    {
        path: ':id',
        name: prop + '.edit',
        meta: { dark: mode, auth: true, name: 'Edit a Press' },
        component: () =>
            import( /* webpackChunkName: "pressEdit" */ '../views/Press/Edit' )
    }
];
const dealsChildRoute = (prop, mode = false) => [
    {
        path: '',
        name: prop + '.list',
        meta: { dark: mode, auth: true, name: 'All Deals' },
        component: () =>
            import( /* webpackChunkName: "dealsList" */ '../views/Deals/List' )
    },
    {
        path: ':id',
        name: prop + '.edit',
        meta: { dark: mode, auth: true, name: 'Edit a Deal' },
        component: () =>
            import( /* webpackChunkName: "dealsEdi t" */ '../views/Deals/Edit' )
    }
];
const milesChildRoute = (prop, mode = false) => [
    {
        path: '',
        name: prop + '.list',
        meta: { dark: mode, auth: true, name: 'Miles Calculator Data' },
        component: () =>
            import( /* webpackChunkName: "milesList" */ '../views/Miles/List' )
    }
];
const statesChildRoute = (prop, mode = false) => [
    {
        path: 'order-yearly',
        name: prop + '.list-ory',
        meta: { dark: mode, auth: true, name: 'Jahresumsatzstatistik' },
        component: () =>
            import( /* webpackChunkName: "statesOrderListY" */ '../views/States/Order/ListY' ),
    },
    {
        path: 'order-monthly',
        name: prop + '.list-orm',
        meta: { dark: mode, auth: true, name: 'Monatsumsatzstatistik' },
        component: () =>
            import( /* webpackChunkName: "statesOrderListM" */ '../views/States/Order/ListM' ),
    },
    {
        path: 'order-proland',
        name: prop + '.list-orpl',
        meta: { dark: mode, auth: true, name: 'Nach Land und Produkt' },
        component: () =>
            import( /* webpackChunkName: "statesOrderListM" */ '../views/States/Order/ListPL' ),
    },
    {
        path: 'customer',
        name: prop + '.list-cry',
        meta: { dark: mode, auth: true, name: 'Customer States' },
        component: () =>
            import( /* webpackChunkName: "statesCustomerList" */ '../views/States/Customer/ListY' ),
    },
    {
        path: 'sales',
        name: prop + '.list-sales',
        meta: { dark: mode, auth: true, name: 'Umsatz nach Ländern (EU)' },
        component: () =>
            import( /* webpackChunkName: "statesSalesList" */ '../views/States/Sales/List' ),
    },
    {
        path: 'tax',
        name: prop + '.list-tax',
        meta: { dark: mode, auth: true, name: 'Berechnung USt' },
        component: () =>
            import( /* webpackChunkName: "statesTaxList" */ '../views/States/Tax/List' ),
    },
    {
        path: 'tax-return',
        name: prop + '.list-tax-return',
        meta: { dark: mode, auth: true, name: 'Steuererklarung Ust' },
        component: () =>
            import( /* webpackChunkName: "statesTaxReturn" */ '../views/States/Tax/Return' ),
    },
    {
        path: 'expenses',
        name: prop + '.list-expenses',
        meta: { dark: mode, auth: true, name: 'Costing State' },
        component: () =>
            import( /* webpackChunkName: "statesExpensesList" */ '../views/States/Expenses/List' ),
    }
];

const milessalesChildRoute = (prop, mode = false) => [
    
    {
        path: 'orders',
        name: prop + '.list-orders',
        meta: { dark: mode, auth: true, name: 'Annual Sales States' },
        component: () =>
            import( /* webpackChunkName: "milesSalesList" */ '../views/MilesSales/List' ),
    },
    {
        path: 'message/:id',
        name: prop + '.message',
        meta: { dark: mode, auth: true, name: 'Message Detail' },
        component: () =>
            import( /* webpackChunkName: "MilesSalesMessage" */ '../views/MilesSales/Message' )
    },
    
];

const routes = [
    {
        path: '/',
        name: 'dashboard',
        redirect: '/home',
        component: Layout,
        meta: { auth: true },
        children: dashboardChildRoutes('dashboard')
    },
    {
        path: '/auth',
        redirect: '/auth/sign-in',
        component: AuthLayout,
        meta: { auth: false },
        children: authChildRoutes('auth')
    },
    {
        path: '/user',
        name: 'user',
        component: Layout,
        meta: { auth: true },
        children: userChildRoute('user')
    },
    {
        path: '/role',
        name: 'role',
        component: Layout,
        meta: { auth: true },
        children: roleChildRoute('role')
    },
    {
        path: '/customer',
        name: 'customer',
        component: Layout,
        meta: { auth: true },
        children: customerChildRoute('customer')
    },
    {
        path: '/extensions',
        name: 'extensions',
        component: Layout,
        meta: { auth: true },
        children: extensionsChildRoute('extensions')
    },
    {
        path: '/order',
        name: 'order',
        component: Layout,
        meta: { auth: true },
        children: orderChildRoute('order')
    },
    {
        path: '/billing',
        name: 'billing',
        component: Layout,
        meta: { auth: true },
        children: billingChildRoute('billing')
    },
    {
        path: '/seminar',
        name: 'seminar',
        component: Layout,
        meta: { auth: true },
        children: seminarChildRoute('seminar')
    },
    {
        path: '/invoices',
        name: 'invoices',
        component: Layout,
        meta: { auth: true },
        children: invoicesChildRoute('invoices')
    },
    {
        path: '/product',
        name: 'product',
        component: Layout,
        meta: { auth: true },
        children: productChildRoute('product')
    },
    {
        path: '/subscribe',
        name: 'subscribe',
        component: Layout,
        meta: { auth: true },
        children: subscriberChildRoute('subscribe')
    },
    {
        path: '/voucher',
        name: 'voucher',
        component: Layout,
        meta: { auth: true },
        children: voucherChildRoute('voucher')
    },
    {
        path: '/enquiries',
        name: 'enquiries',
        component: Layout,
        meta: { auth: true },
        children: enquiriesChildRoute('enquiries')
    },
    {
        path: '/lottery',
        name: 'lottery',
        component: Layout,
        meta: { auth: true },
        children: lotteryChildRoute('lottery')
    },
    {
        path: '/founder-club',
        name: 'founder-club',
        component: Layout,
        meta: { auth: true },
        children: founderClubChildRoute('founder-club')
    },
    {
        path: '/founder-club-mail',
        name: 'founder-club-mail',
        component: Layout,
        meta: { auth: true },
        children: founderClubMailChildRoute('founder-club-mail')
    },
    {
        path: '/event',
        name: 'event',
        component: Layout,
        meta: { auth: true },
        children: eventChildRoute('event')
    },
    {
        path: '/media',
        name: 'media',
        component: Layout,
        meta: { auth: true },
        children: mediaChildRoute('media')
    },
    {
        path: '/support',
        name: 'support',
        component: Layout,
        meta: { auth: true },
        children: supportChildRoute('support')
    },
    {
        path: '/message',
        name: 'message',
        component: Layout,
        meta: { auth: true },
        children: messageChildRoute('message')
    },
    {
        path: '/template',
        name: 'template',
        component: Layout,
        meta: { auth: true },
        children: templateChildRoute('template')
    },
    {
        path: '/hotel',
        name: 'hotel',
        component: Layout,
        meta: { auth: true },
        children: hotelChildRoute('hotel')
    },
    {
        path: '/expense',
        name: 'expense',
        component: Layout,
        meta: { auth: true },
        children: accountChildRoute('expense')
    },
    {
        path: '/publication',
        name: 'publication',
        component: Layout,
        meta: { auth: true },
        children: publicationChildRoute('publication')
    },
    {
        path: '/referral',
        name: 'referral',
        component: Layout,
        meta: { auth: true },
        children: referralChildRoute('referral')
    },
    {
        path: '/booking',
        name: 'booking',
        component: Layout,
        meta: { auth: true },
        children: bookingChildRoute('booking')
    },
    {
        path: '/pages',
        name: 'pages',
        component: Layout,
        meta: { auth: true },
        children: pagesChildRoute('page')
    },
    {
        path: '/promos',
        name: 'promos',
        component: Layout,
        meta: { auth: true },
        children: promosChildRoute('promos')
    },
    {
        path: '/press',
        name: 'press',
        component: Layout,
        meta: { auth: true },
        children: pressChildRoute('press')
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: Layout,
        meta: { auth: true },
        children: notificationsChildRoute('notifications')
    },
    {
        path: '/newsletter',
        name: 'newsletter',
        component: Layout,
        meta: { auth: true },
        children: newsletterChildRoute('newsletter')
    },
    {
        path: '/receiver',
        name: 'receiver',
        component: Layout,
        meta: { auth: true },
        children: receiverChildRoute('receiver')
    },
    {
        path: '/deals-navigator',
        name: 'deals',
        component: Layout,
        meta: { auth: true },
        children: dealsChildRoute('deals')
    },
    {
        path: '/miles-calculator',
        name: 'miles',
        component: Layout,
        meta: { auth: true },
        children: milesChildRoute('miles')
    },
    {
        path: '/states',
        name: 'states',
        component: Layout,
        meta: { auth: true },
        children: statesChildRoute('states')
    },
    {
        path: '/miles-sales',
        name: 'states',
        component: Layout,
        meta: { auth: true },
        children: milessalesChildRoute('milessales')
    },
    {
        path: '/forbidden',
        name: 'forbidden',
        meta: { auth: false },
        component: () =>
            import( /* webpackChunkName: "Forbidden" */ '../views/Error/Error500' )
    },
    {
        path: '404',
        alias: '*',
        name: 'error',
        meta: { auth: false },
        component: () =>
            import( /* webpackChunkName: "Error404" */ '../views/Error/Error404' )
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.MIX_SENTRY_DSN_INDEX,
    routes
});

router.beforeEach((to, from, next) => {
    const userPages = ['dashboard'];
    const loggedIn = sessionStorage.getItem('user');
    if (to.matched.some(record => record.meta.auth)) {
        if (loggedIn) {
            const user = JSON.parse( sessionStorage.getItem('user_data') );
            if ( user ) {
                let parent = to.matched[0],
                    permissions = user.role.permissions
                if( (permissions === 'all') || permissions.includes( parent.name ) || userPages.includes( parent.name ) || ((parent.name === 'user') && (user.id === parseInt(to.params.id))) ) {
                    next()
                } else {
                    next({ name: 'forbidden' })
                }
            }
        }
        else {
            next({ name: 'auth.sign-in' })
        }
    }
    next()
});

export default router
