<template>
    <b-img-lazy v-bind="imgProps" :src="getImageUrl()"/>
</template>

<script>
    import Vue from 'vue'

    export default Vue.extend({
        mixins: [
            {
                data () {
                    return {
                        imgProps: {
                            blank: true,
                            blankColor: '#bbb',
                            width: 40,
                            height: 40
                        }
                    }
                },
                mounted () {},
                components: {},
                methods: {
                    getImageUrl () {
                        let type = this.params.data.type;
                        if ( type === 'pdf' ) {
                            return APP_URL + '/images/icon-file-pdf.png'
                        }
                        else if ( _.includes( ['GIF','JPG','JPEG','jpg','jpeg','png'], type ) ) {
                            return CDN_URL_THUMB + this.params.value;
                        }
                        else {
                            return APP_URL + '/images/icon-file-error.png'
                        }
                    }
                }
            }
        ]
    })
</script>
