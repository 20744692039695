<template>
    <span>{{ params.value | formatDate( params.time ) }}</span>
</template>

<script>
    import Vue from 'vue'

    export default Vue.extend({
        mixins: [
            {
                data () {},
                mounted () {},
                components: {},
                methods: {}
            }
        ]
    })
</script>
