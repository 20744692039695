import Vue from 'vue'

import { AgGridVue } from 'ag-grid-vue'

import 'ag-grid-community/dist/styles/ag-grid.min.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'

Vue.component( AgGridVue );

// Create a mixin to apply the global settings to all grids
Vue.mixin({
    beforeMount() {
        if (this.gridOptions) {
            this.gridOptions.enableCellTextSelection = true;
        }
    }
});