<template>
  <ul class="iq-submenu" :id="id">
    <li v-for="(item, index) in items" :key="index" :class="item.is_heading ? 'iq-menu-title' : activeLink(item) && item.children ? 'active' : activeLink(item) ? 'active' : ''" @mouseover="showSubmenu(index)" @mouseleave="hideSubmenu(index)">
      <router-link :to="item.link" :class="`iq-waves-effect ${activeLink(item) && item.children ? 'active' : activeLink(item) ? 'active' : ''}`">
        <span>{{ $t(item.name) }}</span>
      </router-link>
      <SubMenu v-if="item.children" :items="item.children" :id="item.name" v-show="isSubmenuVisible(index)" />
    </li>
  </ul>
</template>
<script>
import { vito } from '../../config/pluginInit'

export default {
  name: 'SubMenu',
  props: {
    items: Array,
    id: String
  },
  data () {
    return {
      visibleSubmenus: []
    }
  },
  watch: {
    items: {
      handler(newItems) {
        this.initializeVisibleSubmenus(newItems);
      },
      immediate: true
    }
  },
  created() {
    this.initializeVisibleSubmenus(this.items);
  },
  components: {},
  computed: {},
  mounted () {
  },
  methods: {
    activeLink (item) {
      return vito.getActiveLink(item, this.$route.name)
    },
    showSubmenu (index) {
      this.$set(this.visibleSubmenus, index, true)
    },
    hideSubmenu (index) {
      this.$set(this.visibleSubmenus, index, false)
    },
    isSubmenuVisible (index) {
      return this.visibleSubmenus[index]
    },
    initializeVisibleSubmenus(items) {
      this.visibleSubmenus = items.map(() => false);
    }
  }
}
</script>
