<template>
    <div>
        <footer class="iq-footer">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        Copyright {{ this.year }}. All Rights Reserved.
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
import {getYear} from "../../Utils/helpers"

export default {
    name: 'Footer',
    data () {
        return {
            year: getYear()
        }
    }
}
</script>
