<template>
    <div class="iq-sidebar">
        <div class="iq-sidebar-logo d-flex justify-content-between">
            <router-link :to="{ name: 'dashboard.home' }">
                <img :src="logo" class="img-fluid" alt="logo">
            </router-link>
            <div class="iq-menu-bt-sidebar">
                <div class="iq-menu-bt align-self-center">
                    <div class="wrapper-menu" @click="miniSidebar">
                        <div class="main-circle"><i class="ri-menu-3-line"/></div>
                        <div class="hover-circle"><i class="ri-arrow-left-s-line"/></div>
                    </div>
                </div>
            </div>
        </div>
        <div id="sidebar-scrollbar">
            <nav class="iq-sidebar-menu">
                <List :items="items" :open="true" />
            </nav>
            <div class="p-3"></div>
        </div>
    </div>
    <!-- TOP Nav Bar -->
</template>

<script>
    import List from './List'
    import logo from '../../../../images/logo.png';
    export default {
        name: 'SideBar',
        props: {
            items: { type: Array }
        },
        components: {
            List
        },
        methods: {
            miniSidebar () {
                this.$emit('toggle')
            }
        },
        data () {
            return {
                logo: logo
            }
        }
    }
</script>
