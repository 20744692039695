import Scrollbar from 'smooth-scrollbar'
import Snackbar from 'node-snackbar'
const $ = require('jquery');

if (typeof window !== 'undefined') {
    window.$ = $;
    window.jQuery = $;
    require('bootstrap/js/src/tab')
}

export const vito = {
    index () {
        this.loaderOut();
        this.SmoothScrollbar();
    },
    mainIndex () {
        this.ripple();
        this.sideBarToggle();
        this.navBarAction();
    },
    loaderIn () {
        let load = document.getElementById('loading');
        load.classList.remove('d-none');
        animation.fadeIn(load, { duration: 400 });
    },
    loaderOut () {
        let load = document.getElementById('loading');
        animation.fadeOut(load, { duration: 1400 });
        setTimeout(() => {
            load.classList.add('d-none')
        }, 1500)
    },
    ripple () {
        $(document).on('click', '.iq-waves-effect', function (e) {
            // Remove any old one
            $('.ripple').remove();
            // Setup
            let posX = $(this).offset().left;
            let posY = $(this).offset().top;
            let buttonWidth = $(this).width();
            let buttonHeight = $(this).height();
            // Add the element
            $(this).prepend("<span class='ripple'></span>");
            // Make it round!
            if (buttonWidth >= buttonHeight) {
                buttonHeight = buttonWidth;
            } else {
                buttonWidth = buttonHeight;
            }
            // Get the center of the element
            let x = e.pageX - posX - buttonWidth / 2;
            let y = e.pageY - posY - buttonHeight / 2;
            // Add the ripples CSS and start the animation
            $('.ripple').css({
                width: buttonWidth,
                height: buttonHeight,
                top: y + 'px',
                left: x + 'px'
            }).addClass('rippleEffect')
        })
    },
    triggerSet () {
        const elementExist = this.checkElement('class', 'wrapper-menu');
        if (elementExist) {
            let wrapperMenu = document.querySelector('.wrapper-menu');
            let body = document.querySelector('body');
            this.sidebarMiniSetEvent(wrapperMenu, body)
            /* for (let i = 0; i < wrapperMenu.length; i++) {
            } */
        }
    },
    sidebarMiniSetEvent (element, target) {
        element.classList.toggle('open');
        target.classList.toggle('sidebar-main')
    },
    sideBarToggle () {
        const elementExist = this.checkElement('class', 'iq-sidebar-menu');
        if (elementExist) {
            $(document).on('click', '.iq-sidebar-menu li', function () {

            })
        }
    },
    navBarAction () {
        $(document).on('click', '.iq-sub-dropdown', function (e) {
            e.stopPropagation()
        });
        $(document).on('click', function (e) {
            let myTargetElement = e.target;
            let selector, mainElement;
            if ($(myTargetElement).hasClass('search-toggle') || $(myTargetElement).parent().hasClass('search-toggle') || $(myTargetElement).parent().parent().hasClass('search-toggle')) {
                if ($(myTargetElement).hasClass('search-toggle')) {
                    selector = $(myTargetElement).parent();
                    mainElement = $(myTargetElement)
                } else if ($(myTargetElement).parent().hasClass('search-toggle')) {
                    selector = $(myTargetElement).parent().parent();
                    mainElement = $(myTargetElement).parent()
                } else if ($(myTargetElement).parent().parent().hasClass('search-toggle')) {
                    selector = $(myTargetElement).parent().parent().parent();
                    mainElement = $(myTargetElement).parent().parent()
                }
                if (!mainElement.hasClass('active') && $('.navbar-list li').find('.active')) {
                    $('.navbar-list li').removeClass('iq-show');
                    $('.navbar-list li .search-toggle').removeClass('active')
                }

                selector.toggleClass('iq-show');
                mainElement.toggleClass('active');

                e.preventDefault()
            } else if ($(myTargetElement).is('.search-input')) {} else {
                $('.navbar-list li').removeClass('iq-show');
                $('.navbar-list li .search-toggle').removeClass('active')
            }
        })
    },
    checkElement (type, element) {
        let found = false;
        let elements;
        switch (type) {
            case 'class':
                elements = document.getElementsByClassName(element);
                if (elements !== undefined && elements !== null && elements.length > 0) {
                    found = true
                }
                break;

            case 'id':
                elements = document.getElementById(element);

                if (elements !== undefined && elements !== null) {
                    found = true
                }
                break
        }
        return found
    },
    SmoothScrollbar () {
        const elementExistMain = this.checkElement('id', 'sidebar-scrollbar');
        if (elementExistMain) {
            Scrollbar.init(document.querySelector('#sidebar-scrollbar'))
        }
        const elementExistRight = this.checkElement('id', 'right-sidebar-scrollbar');
        if (elementExistRight) {
            Scrollbar.init(document.querySelector('#right-sidebar-scrollbar'))
        }
    },
    getActiveLink (item, activeRoute) {
        let active = false;
        if (item.children !== undefined) {
            item.children.filter(function (child) {
                if (child.link.name === activeRoute) {
                    active = true
                }
            })
        } else {
            if (item.link.name === activeRoute) {
                active = true
            }
        }
        return active
    },
    showSnackbar (type, data = {}) {
        if (type !== null) {
            switch (type) {
                case 'success':
                    Snackbar.show({ text: data, backgroundColor: '#4caf50', actionTextColor: '#ffffff', pos: 'bottom-right' });
                    break;
                case 'error':
                    Snackbar.show({ text: data, backgroundColor: '#b21f2d', actionTextColor: '#ffffff', pos: 'bottom-right' });
                    break;
                case 'info':
                    Snackbar.show({ text: data, backgroundColor: '#2196f3', actionTextColor: '#ffffff', pos: 'bottom-right' });
                    break;
                case 'warning':
                    Snackbar.show({ text: data, backgroundColor: '#996600', actionTextColor: '#ffffff', pos: 'bottom-right' });
                    break;
            }
        } else {
            Snackbar.show(data)
        }
    }
};

export const animation = {
    easing: {
        linear: function (progress) {
            return progress
        },
        quadratic: function (progress) {
            return Math.pow(progress, 2)
        },
        swing: function (progress) {
            return 0.5 - Math.cos(progress * Math.PI) / 2
        },
        circ: function (progress) {
            return 1 - Math.sin(Math.acos(progress))
        },
        back: function (progress, x) {
            return Math.pow(progress, 2) * ((x + 1) * progress - x)
        },
        bounce: function (progress) {
            // eslint-disable-next-line no-unused-vars
            for (let a = 0, b = 1, result; 1; a += b, b /= 2) {
                if (progress >= (7 - 4 * a) / 11) {
                    return -Math.pow((11 - 6 * a - 11 * progress) / 4, 2) + Math.pow(b, 2)
                }
            }
        },
        elastic: function (progress, x) {
            return Math.pow(2, 10 * (progress - 1)) * Math.cos(20 * Math.PI * x / 3 * progress)
        }
    },
    animate: function (options) {
        let start = new Date();
        let id = setInterval(function () {
            let timePassed = new Date() - start;
            let progress = timePassed / options.duration;
            if (progress > 1) {
                progress = 1
            }
            options.progress = progress;
            let delta = options.delta(progress);
            options.step(delta);
            if (progress === 1) {
                clearInterval(id)
            }
        }, options.delay || 10)
    },
    fadeOut: function (element, options) {
        let to = 1;
        this.animate({
            duration: options.duration,
            delta: function (progress) {
                progress = this.progress;
                return animation.easing.swing(progress)
            },
            step: function (delta) {
                element.style.opacity = to - delta
            }
        })
    },
    fadeIn: function (element, options) {
        let to = 0;
        this.animate({
            duration: options.duration,
            delta: function (progress) {
                progress = this.progress;
                return animation.easing.swing(progress)
            },
            step: function (delta) {
                element.style.opacity = to + delta
            }
        })
    }
};
