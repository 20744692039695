<template>
    <div class="iq-card ">
        <div class="iq-card-header">
            <div class="iq-header-title d-block">
                <div class="text-center">
                    <h3 class="card-title">Sign in</h3>
                    <p class="text-center">Enter your email address and password to access dashboard.</p>
                </div>
            </div>
        </div>
        <div class="card-body iq-card-body ">
            <sign-in-form />
        </div>
    </div>
</template>

<script>
    import SignInForm from './SignInForm'

    export default {
        name: 'SignIn',
        components: { SignInForm },
        mounted () {
            const loggedIn = sessionStorage.getItem('user');
            if (loggedIn) {
                this.$router.push({ name: 'dashboard.home' })
            }
        },
        methods: {}
    }
</script>
